// src/pages/Signup.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  CircularProgress,
  Alert,
  Paper,
  Avatar,
} from '@mui/material';
import { api } from '../services/authService';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../redux/authSlice';
import logoCircle from '../assets/NovauraCircleIcon.png'

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm = () => {
    if (!username.trim() || !email.trim() || !password || !confirmPassword) {
      setError('All fields are required.');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return false;
    }
    // Add more validation rules if necessary
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      // Register the user
      const response = await api.post('/accounts/users/', {
        username,
        email,
        password,
      });

      if (response.status === 201) {
        // Registration successful, now log in the user
        // Fetch tokens
        const loginResponse = await api.post('/accounts/token/', {
          username,
          password,
        });
        const { access, refresh } = loginResponse.data;

        // Fetch user information
        const userResponse = await api.get('/accounts/users/me/', {
          headers: { Authorization: `Bearer ${access}` },
        });

        // Dispatch credentials to Redux store
        dispatch(
          setCredentials({
            user: userResponse.data,
            token: access,
            refreshToken: refresh,
          })
        );

        // Store tokens in localStorage
        localStorage.setItem('token', access);
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('user', JSON.stringify(userResponse.data));

        // Redirect to dashboard
        navigate('/crm/dashboard');
      }
    } catch (err) {
      console.error('Signup error:', err);
      if (err.response) {
        setError(
          err.response.data.detail ||
            'An error occurred during signup. Please try again.'
        );
      } else {
        setError('An error occurred during signup. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: '#2E2E2E',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ p: 3, backgroundColor: '#3C3C3C' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ m: 1, width: 64, height: 64 }}>
              <img src={logoCircle} alt="Novaura Icon" style={{ width: '100%' }} />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: '#FFFFFF' }}>
              Sign Up for Novaura CRM
            </Typography>
            {error && (
              <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            )}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={isLoading}
                InputLabelProps={{ style: { color: '#FFFFFF' } }} // White text for label
                InputProps={{ style: { color: '#FFFFFF' } }} // White text for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                InputLabelProps={{ style: { color: '#FFFFFF' } }} // White text for label
                InputProps={{ style: { color: '#FFFFFF' } }} // White text for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                InputLabelProps={{ style: { color: '#FFFFFF' } }} // White text for label
                InputProps={{ style: { color: '#FFFFFF' } }} // White text for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={isLoading}
                InputLabelProps={{ style: { color: '#FFFFFF' } }} // White text for label
                InputProps={{ style: { color: '#FFFFFF' } }} // White text for input
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Sign Up'}
              </Button>
              <Typography variant="body2" align="center" sx={{ color: '#FFFFFF' }}>
                Already have an account?{' '}
                <Link to="/login" style={{ color: '#FF5A79' }}>Sign in!</Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Signup;
