// src/pages/IntegrationsPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import CategorySidebar from '../components/CategorySidebar'; // Import the new component
import IntegrationsTable from '../components/IntegrationsTable'; // Move the table to its own component
import { categories } from '../data/categories';

const IntegrationsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedCategory, setSelectedCategory] = useState('All Integrations');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [newIntegration, setNewIntegration] = useState({
    name: '',
    type: '',
    category: '',
  });

  const [integrations, setIntegrations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // State for mobile drawer
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAddIntegration = () => {
    setOpenAddModal(true);
  };

  const handleModalClose = () => {
    setOpenAddModal(false);
    setNewIntegration({ name: '', type: '', category: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIntegration((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = () => {
    // Logic to submit the new integration
    console.log('Submitting new integration:', newIntegration);
    // Close modal and reset form
    handleModalClose();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setIntegrations([
        { id: 1, name: 'Mailchimp', type: 'API', status: 'Active', category: 'Marketing Automation' },
        { id: 2, name: 'Salesforce', type: 'API', status: 'Active', category: 'Sales Enablement' },
        { id: 3, name: 'Slack', type: 'Webhook', status: 'Active', category: 'Communication' },
        // ... other integrations
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setMobileOpen(false); // Close drawer on mobile after selecting
  };

  // Filter integrations based on category and search query
  const filteredIntegrations = integrations.filter((int) => {
    const matchesCategory =
      selectedCategory === 'All Integrations' || int.category === selectedCategory;
    const matchesSearch = int.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sidebar */}
      {!isMobile ? (
        <Box
          sx={{
            width: 240,
            flexShrink: 0,
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <CategorySidebar
            selectedCategory={selectedCategory}
            onSelectCategory={handleCategorySelect}
          />
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
          <Dialog open={mobileOpen} onClose={handleDrawerToggle} fullScreen>
            <CategorySidebar
              selectedCategory={selectedCategory}
              onSelectCategory={handleCategorySelect}
            />
          </Dialog>
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
            {selectedCategory}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddIntegration}
          >
            Add New Integration
          </Button>
        </Box>

        {/* Search Bar */}
        <TextField
          placeholder="Search Integrations"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Content */}
        {loading ? (
          <CircularProgress color="secondary" />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : filteredIntegrations.length > 0 ? (
          <IntegrationsTable data={filteredIntegrations} />
        ) : (
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
            No integrations found.
          </Typography>
        )}

        {/* Add Integration Modal */}
        <Dialog open={openAddModal} onClose={handleModalClose} fullWidth maxWidth="sm">
          <DialogTitle>Add New Integration</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Integration Name"
              type="text"
              fullWidth
              variant="outlined"
              value={newIntegration.name}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              select
              margin="dense"
              name="type"
              label="Integration Type"
              fullWidth
              variant="outlined"
              value={newIntegration.type}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="Webhook">Webhook</MenuItem>
              <MenuItem value="API">API</MenuItem>
              <MenuItem value="Native">Native</MenuItem>
            </TextField>
            <TextField
              select
              margin="dense"
              name="category"
              label="Category"
              fullWidth
              variant="outlined"
              value={newIntegration.category}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary" variant="contained">
              Add Integration
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default IntegrationsPage;
