import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  CircularProgress,
  Alert,
  Paper,
  Avatar,
} from '@mui/material';
import { apiWithoutAuth } from '../services/authService';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../redux/snackbarSlice';
import logoCircle from '../assets/NovauraCircleIcon.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess(false);

    try {
      const response = await apiWithoutAuth.post('/accounts/password/reset/', {
        email,
      });

      if (response.status === 200) {
        setSuccess(true);
        setEmail(''); // Clear the email field
        dispatch(showSnackbar({
          open: true,
          message: 'Password reset link has been sent to your email.',
          severity: 'success'
        }));
      }
    } catch (err) {
      console.error('Error sending password reset email:', err);
      setError(
        err.response?.data?.detail ||
        'An error occurred while sending the password reset email.'
      );
      dispatch(showSnackbar({
        open: true,
        message: 'Failed to send reset email. Please try again.',
        severity: 'error'
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: '#2E2E2E',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper 
          elevation={6} 
          sx={{ 
            p: 4, 
            backgroundColor: '#3C3C3C',
            borderRadius: 2
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center'
            }}
          >
            <Avatar 
              sx={{ 
                m: 1, 
                width: 64, 
                height: 64,
                backgroundColor: 'transparent' 
              }}
            >
              <img 
                src={logoCircle} 
                alt="Novaura Icon" 
                style={{ width: '100%' }} 
              />
            </Avatar>

            <Typography 
              component="h1" 
              variant="h5" 
              sx={{ 
                color: '#FFFFFF',
                mb: 2
              }}
            >
              Forgot Password
            </Typography>

            {success ? (
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Alert 
                  severity="success" 
                  sx={{ 
                    mb: 2,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    color: '#98FB98'
                  }}
                >
                  Check your email for password reset instructions.
                </Alert>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#FFFFFF',
                    mt: 2 
                  }}
                >
                  Didn't receive the email?{' '}
                  <Button
                    onClick={() => {
                      setSuccess(false);
                      setEmail('');
                    }}
                    sx={{ 
                      color: '#FF5A79',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 90, 121, 0.1)'
                      }
                    }}
                  >
                    Try again
                  </Button>
                </Typography>
              </Box>
            ) : (
              <>
                {error && (
                  <Alert 
                    severity="error" 
                    sx={{ 
                      mt: 2, 
                      width: '100%',
                      backgroundColor: 'rgba(211, 47, 47, 0.1)',
                      color: '#FF6B6B'
                    }}
                  >
                    {error}
                  </Alert>
                )}

                <Box 
                  component="form" 
                  onSubmit={handleSubmit} 
                  sx={{ 
                    mt: 1,
                    width: '100%'
                  }}
                >
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: '#B4C0D3',
                      mb: 2
                    }}
                  >
                    Enter your email address and we'll send you instructions to reset your password.
                  </Typography>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isSubmitting}
                    InputLabelProps={{ 
                      style: { color: '#FFFFFF' },
                      shrink: true
                    }}
                    InputProps={{ 
                      style: { color: '#FFFFFF' },
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#6B7280'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#9CA3AF'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#FF5A79'
                        }
                      }
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ 
                      mt: 3, 
                      mb: 2,
                      backgroundColor: '#FF5A79',
                      '&:hover': {
                        backgroundColor: '#FF4064'
                      },
                      height: 48
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} sx={{ color: '#FFFFFF' }} />
                    ) : (
                      'Send Reset Link'
                    )}
                  </Button>

                  <Typography 
                    variant="body2" 
                    align="center" 
                    sx={{ color: '#B4C0D3' }}
                  >
                    Remembered your password?{' '}
                    <Link 
                      to="/login" 
                      style={{ 
                        color: '#FF5A79',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      Sign in!
                    </Link>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
