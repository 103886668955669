// src/components/NotificationsPopover.js

import React from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  Box,
  Button,
  Divider,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { markNotificationAsRead } from '../redux/notificationSlice';
import { useNavigate } from 'react-router-dom';

const NotificationsPopover = ({ anchorEl, open, onClose }) => {
  const notifications = useSelector((state) => state.notifications.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNotificationClick = (notification) => {
    dispatch(markNotificationAsRead(notification.id));
    // Optionally navigate or perform action based on notification
    onClose();
  };

  const handleViewAllClick = () => {
    navigate('/crm/notifications');
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        style: {
          width: 300,
        },
      }}
    >
      <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Notifications</Typography>
        <Button size="small" onClick={handleViewAllClick}>
          View All
        </Button>
      </Box>
      <Divider />
      <List
        sx={{
          maxHeight: 400, // Adjust as needed
          overflowY: 'auto',
        }}
      >
        {notifications.length === 0 ? (
          <ListItem>
            <ListItemText primary="No notifications" />
          </ListItem>
        ) : (
          notifications.map((notification) => (
            <ListItemButton key={notification.id} onClick={() => handleNotificationClick(notification)}>
              <ListItemText
                primary={notification.message}
                secondary={new Date(notification.created_at).toLocaleString()}
              />
            </ListItemButton>
          ))
        )}
      </List>
    </Popover>
  );
};

export default NotificationsPopover;
