// src/components/TopNavBar.js

import React from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../redux/authSlice';
import NotificationIcon from './NotificationIcon';

const TopNavBar = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const token = useSelector(selectCurrentToken);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${open ? 240 : 60}px)`,
        ml: `${open ? 240 : 60}px`,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
        transition: theme.transitions.create(['width', 'margin-left'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Toolbar>
        {/* IconButton to toggle the drawer */}
        <IconButton
          color="inherit"
          aria-label={open ? 'Collapse sidebar' : 'Expand sidebar'}
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>

        {/* Spacer to push content to the right */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Notification Icon */}
        {token && <NotificationIcon />}
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
