// src/components/LeadsManagement.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, Stack, TextField, Select, MenuItem, Snackbar, Box,
  TablePagination, CircularProgress, FormControl, InputLabel,
  Alert, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
  Grid, ListItemIcon, ListItemText, Menu
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { api } from '../services/authService';
import './LeadsManagement.css';
import { useTheme } from '@mui/material/styles';
import TablePaginationActions from '../components/TablePaginationActions';
import Papa from 'papaparse';

import useLocalStorage from '../hooks/useLocalStorage';

import BulkActionsMenu from '../components/BulkActionsMenu';
import ErrorModal from '../components/ErrorModal';
import LeadsTable from '../components/LeadsTable';

const LeadsManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);

  // State variables
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useLocalStorage('selectedAccount', '');
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useLocalStorage('selectedCampaign', '');
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [funnels, setFunnels] = useState([]);
  const [selectedFunnel, setSelectedFunnel] = useLocalStorage('selectedFunnel', '');
  const [campaignMembers, setCampaignMembers] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [funnelSteps, setFunnelSteps] = useState([]);
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]); // New state for dynamic fields

  // State for menus and dialogs
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState(null);
  const [assignFunnelAnchorEl, setAssignFunnelAnchorEl] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [currentLead, setCurrentLead] = useState(null);

  const [loading, setLoading] = useState(false);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLeads, setTotalLeads] = useState(0);
  const [orderBy, setOrderBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const [editingCell, setEditingCell] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fileInputRef = useRef(null);
  const [importErrors, setImportErrors] = useState([]);
  const [failedRowsData, setFailedRowsData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // Confirmation Dialogs
  const [bulkDeleteConfirmationOpen, setBulkDeleteConfirmationOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [leadToConfirm, setLeadToConfirm] = useState(null);
  const [warningMessage, setWarningMessage] = useState([]);

  // Regular Expressions
  const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Loading states
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [campaignsLoading, setCampaignsLoading] = useState(true);

  // Fetch accounts with loading state
  useEffect(() => {
    const fetchAccounts = async () => {
      setAccountsLoading(true);
      try {
        const response = await api.get('/crm/accounts/');
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        setAccountsLoading(false);
      }
    };
    fetchAccounts();
  }, []);

  // Validate selectedAccount after accounts are loaded
  useEffect(() => {
    if (!accountsLoading && selectedAccount) {
      const accountExists = accounts.some(
        (account) => String(account.id) === String(selectedAccount)
      );
      if (!accountExists) {
        setSelectedAccount('');
      }
    }
  }, [accountsLoading, accounts, selectedAccount]);

  // Fetch campaigns when selectedAccount changes
  useEffect(() => {
    const fetchCampaigns = async () => {
      if (selectedAccount) {
        setCampaignsLoading(true);
        try {
          const response = await api.get(`/crm/campaigns/?account_id=${selectedAccount}`);
          setCampaigns(response.data);
        } catch (error) {
          console.error('Error fetching campaigns:', error);
        } finally {
          setCampaignsLoading(false);
        }
      } else {
        setCampaigns([]);
      }
    };
    fetchCampaigns();
  }, [selectedAccount]);

  useEffect(() => {
    if (!campaignsLoading) {
      if (selectedCampaign) {
        if (campaigns.length > 0) {
          const campaignExists = campaigns.some(
            (campaign) => String(campaign.id) === String(selectedCampaign)
          );
          if (!campaignExists) {
            setSelectedCampaign('');
          }
        } else {
          // No campaigns are available; reset selectedCampaign
          setSelectedCampaign('');
        }
      }
      // No selectedCampaign; no action needed
    }
  }, [campaignsLoading, campaigns, selectedCampaign]);

  // Fetch lead statuses
  useEffect(() => {
    const fetchLeadStatuses = async () => {
      try {
        const response = await api.get('/crm/lead-statuses/');
        setLeadStatuses(response.data);
      } catch (error) {
        console.error('Error fetching lead statuses:', error);
      }
    };
    fetchLeadStatuses();
  }, []);

  // Fetch funnels when selectedCampaign changes
  useEffect(() => {
    const fetchFunnels = async () => {
      if (selectedCampaign) {
        try {
          const response = await api.get(`/crm/funnels/?campaign_id=${selectedCampaign}`);
          setFunnels(response.data);
        } catch (error) {
          console.error('Error fetching funnels:', error);
        }
      } else {
        setFunnels([]);
        // Do not reset selectedFunnel here
      }
    };
    fetchFunnels();
  }, [selectedCampaign]);

  // Fetch funnel steps when selectedFunnel changes
  useEffect(() => {
    const fetchFunnelSteps = async () => {
      if (selectedCampaign) {
        try {
          const response = await api.get(`/crm/steps/?funnel_id=${selectedFunnel}&campaign_id=${selectedCampaign}`);
          setFunnelSteps(response.data);
        } catch (error) {
          console.error('Error fetching funnel steps:', error);
        }
      } else {
        setFunnelSteps([]);
      }
    };
    fetchFunnelSteps();
  }, [selectedFunnel, selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign && selectedAccount) {
      fetchCampaignDetails(selectedAccount, selectedCampaign);
      fetchDynamicFields(selectedCampaign); // Fetch dynamic fields
    } else {
      setCampaignDetails(null);
      setCampaignMembers([]);
      setDynamicFields([]);
    }
  }, [selectedCampaign, selectedAccount]);

  useEffect(() => {
    if (selectedCampaign) {
      fetchLeads();
    } else {
      setLeads([]);
      setTotalLeads(0);
    }
  }, [selectedCampaign, selectedFunnel, page, rowsPerPage, orderBy, order, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Wait for 500ms after the user stops typing

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch members of the campaign
  const fetchCampaignDetails = async (accountId, campaignId) => {
    try {
      const response = await api.get(`/crm/campaigns/${campaignId}/?account_id=${accountId}`);
      setCampaignMembers(response.data.users_with_access);
      setCampaignDetails(response.data); // Store campaign details
    } catch (error) {
      console.error('Error fetching campaign details:', error);
    }
  };

  // Fetch dynamic fields for the selected campaign
  const fetchDynamicFields = async (campaignId) => {
    try {
      const response = await api.get(`/crm/campaigns/${campaignId}/selected_lead_fields/`);
      const { lead_field_definitions } = response.data;
      setDynamicFields(lead_field_definitions);
    } catch (error) {
      console.error('Error fetching dynamic fields:', error);
    }
  };

  const fetchLeads = async () => {
    if (!selectedCampaign) return;
    setLoading(true);
    try {
      const response = await api.get('/crm/leads/', {
        params: {
          campaign_id: selectedCampaign,
          funnel_id: selectedFunnel || null,
          page: page + 1,
          page_size: rowsPerPage,
          ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
          search: debouncedSearchTerm,
        },
      });

      setLeads(
        response.data.results.map((lead) => ({
          ...lead,
          field_values: (lead.field_values || []).map((fv) => {
            const fieldDefinitionId = fv.field_definition;
            const fieldDefinition = dynamicFields.find(
              (field) => field.id === fieldDefinitionId
            );
            return {
              field_definition: fieldDefinitionId,
              field_api_name: fieldDefinition ? fieldDefinition.api_name : '',
              value: fv.value,
            };
          }),
        }))
      );
      setTotalLeads(response.data.count || 0);
    } catch (error) {
      console.error('Error fetching leads:', error);
      showSnackbar('Failed to fetch leads', 'error');
      setLeads([]);
      setTotalLeads(0);
    }
    setLoading(false);
  };

  // Update handleAccountChange
  const handleAccountChange = (event) => {
    const newAccountId = event.target.value;
    if (newAccountId !== selectedAccount) {
      setSelectedAccount(newAccountId);
      setSelectedCampaign('');
      setSelectedFunnel('');
      setPage(0);
    }
  };

  // Update handleCampaignChange
  const handleCampaignChange = (event) => {
    const newCampaignId = event.target.value;
    if (newCampaignId !== selectedCampaign) {
      setSelectedCampaign(newCampaignId);
      setSelectedFunnel('');
      setPage(0);
    }
  };

  const handleFunnelChange = (event) => {
    setSelectedFunnel(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCellClick = (leadId, columnId) => {
    setEditingCell({ leadId, columnId });
  };

  const handleLeadSelect = (id, checked = null) => {
    if (id === 'all') {
      setSelectedLeads(checked ? leads.map((lead) => lead.id) : []);
    } else {
      setSelectedLeads((prev) =>
        prev.includes(id)
          ? prev.filter((leadId) => leadId !== id)
          : [...prev, id]
      );
    }
  };

  const handleBulkDelete = () => {
    setBulkDeleteConfirmationOpen(true);
    handleBulkActionsClose();
  };

  const confirmBulkDelete = async () => {
    setBulkDeleteConfirmationOpen(false);
    await handleBulkAction('delete');
  };

  const handleBulkAction = async (action, funnelId = null) => {
    setBulkActionLoading(true);
    try {
      if (action === 'assign') {
        if (!funnelId) {
          showSnackbar('Please select a funnel to assign.', 'error');
          return;
        }
        await api.post('/crm/leads/bulk_assign_funnel/', {
          ids: selectedLeads,
          funnel_id: funnelId,
        });
        showSnackbar('Leads assigned to funnel successfully.', 'success');
      } else if (action === 'delete') {
        await api.post('/crm/leads/bulk_delete/', { ids: selectedLeads });
        showSnackbar('Leads deleted successfully.', 'success');
      }
      setSelectedLeads([]);
      fetchLeads();
    } catch (error) {
      console.error('Error performing bulk action:', error);
      showSnackbar('Failed to perform bulk action.', 'error');
    } finally {
      setBulkActionLoading(false);
    }
  };

  const handleSave = async (leadId) => {
    try {
      const leadToUpdate = leads.find((lead) => lead.id === leadId);

      // Check if the phone number and email are valid
      const isPhoneNumberValid = phoneNumberRegex.test(leadToUpdate.phone_number || '');
      const isEmailValid = emailRegex.test(leadToUpdate.email || '');

      if (!isPhoneNumberValid || !isEmailValid) {
        const warnings = [];
        if (!isPhoneNumberValid) {
          warnings.push('Invalid phone number format. Expected format: XXX-XXX-XXXX.');
        }
        if (!isEmailValid) {
          warnings.push('Invalid email address format. Expected format: example@example.com');
        }

        // Show the confirmation dialog
        setWarningMessage(warnings);
        setLeadToConfirm(leadToUpdate);
        setOpenConfirmation(true);
        return;
      }

      // Proceed to save the lead directly if validation passes
      await saveLead(leadToUpdate);
    } catch (error) {
      console.error('Error saving lead:', error);
      showSnackbar('Failed to save lead', 'error');
    }
  };

  // Function to actually save the lead
  const saveLead = async (leadToUpdate) => {
    try {
      const validFieldValues = (leadToUpdate.field_values || []).filter(
        (fv) => fv.field_definition
      );

      const payload = {
        ...leadToUpdate,
        campaign: selectedCampaign,
        funnel: selectedFunnel,
        last_updated_by: currentUser.id,
        lead_type: campaignDetails ? campaignDetails.campaign_model.name.toLowerCase() : '',
        // Extract just the IDs for related fields
        current_step: leadToUpdate.current_step?.id || leadToUpdate.current_step,  // Handle both object and ID
        status: leadToUpdate.status?.id || leadToUpdate.status,                    // Handle both object and ID
        assigned_to: leadToUpdate.assigned_to?.id || leadToUpdate.assigned_to,     // Handle both object and ID
        field_values: validFieldValues.map((fieldValue) => ({
          field_definition: fieldValue.field_definition,
          value: fieldValue.value,
        })),
      };

      // Remove temporary properties
      delete payload.id;
      delete payload.field_name;
      delete payload.field_api_name;
      delete payload.current_step_name;  // Remove this as well

      // Ensure subclass fields are included
      if (leadToUpdate.lead_type === 'b2b') {
        payload.company = leadToUpdate.company || null;
        payload.job_title = leadToUpdate.job_title || null;
        payload.industry = leadToUpdate.industry || null;
        payload.company_size = leadToUpdate.company_size || null;
        payload.annual_revenue = leadToUpdate.annual_revenue || null;
      } else if (leadToUpdate.lead_type === 'd2c') {
        payload.date_of_birth = leadToUpdate.date_of_birth || null;
        payload.age = leadToUpdate.age || null;
        payload.gender = leadToUpdate.gender || null;
        payload.marital_status = leadToUpdate.marital_status || null;
      }

      if (leadToUpdate.id === 'new') {
        await api.post('/crm/leads/', payload);
        showSnackbar('New lead added successfully', 'success');
      } else {
        await api.put(`/crm/leads/${leadToUpdate.id}/`, payload);
        showSnackbar('Lead updated successfully', 'success');
      }

      setEditingCell(null);
      fetchLeads();
    } catch (error) {
      console.error('Error saving lead:', error);
      showSnackbar('Failed to save lead', 'error');
    }
  };

  // Handle confirmation dialog actions
  const handleConfirmSave = async () => {
    if (leadToConfirm) {
      await saveLead(leadToConfirm);
    }
    setOpenConfirmation(false);
  };

  const handleCancelSave = () => {
    setOpenConfirmation(false);
  };

  const handleCancel = () => {
    setEditingCell(null);
    fetchLeads();
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/crm/leads/${id}/`);
      showSnackbar('Lead deleted successfully', 'success');
      fetchLeads();
    } catch (error) {
      console.error('Error deleting lead:', error);
      showSnackbar('Failed to delete lead', 'error');
    }
  };

  const handleAddRow = () => {
    const leadType = campaignDetails ? campaignDetails.campaign_model.name : '';
    const newLead = {
      id: 'new',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      current_step: '',
      status: '',
      assigned_to: '',
      field_values: [],
      lead_type: leadType.toLowerCase(), // 'b2b' or 'd2c'
      // Include subclass-specific fields
      ...(leadType === 'B2B' && {
        company: '',
        job_title: '',
        industry: '',
        company_size: '',
        annual_revenue: '',
      }),
      ...(leadType === 'D2C' && {
        date_of_birth: '',
        age: '',
        gender: '',
        marital_status: '',
      }),
    };
    setLeads([newLead, ...leads]);
    setEditingCell({ leadId: 'new', columnId: 'first_name' });
  };

  const handleExport = async () => {
    if (!selectedCampaign) {
      showSnackbar('Please select a campaign before exporting', 'error');
      return;
    }

    try {
      setLoading(true);

      const requestBody = {
        campaign_id: selectedCampaign,
      };
      if (selectedFunnel) {
        requestBody.funnel_id = selectedFunnel;
      }

      const response = await api.post('/crm/lead_data/', requestBody, {
        responseType: 'blob',
      });

      const campaign = campaigns.find((t) => t.id === selectedCampaign);
      const funnel = funnels.find((f) => f.id === selectedFunnel);
      const campaignName = campaign ? campaign.name.replace(/\s+/g, '_') : 'unknown_campaign';
      const funnelName = funnel ? funnel.name.replace(/\s+/g, '_') : 'all_funnels';

      const now = new Date();
      const timestamp = now
        .toISOString()
        .replace(/[-:]/g, '')
        .replace('T', '_')
        .slice(0, 15);

      const fileName = `leads_${campaignName}_${funnelName}_${timestamp}.csv`;

      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showSnackbar('Leads exported successfully', 'success');
    } catch (error) {
      console.error('Error exporting leads:', error);
      showSnackbar('Failed to export leads', 'error');
    } finally {
      setLoading(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImport = async (event) => {
    if (!selectedCampaign) {
      showSnackbar('Please select a campaign before importing', 'error');
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      showSnackbar('No file selected', 'error');
      return;
    }

    if (!file.name.endsWith('.csv')) {
      showSnackbar('Please upload a CSV file', 'error');
      return;
    }

    // Parse the CSV file to map row numbers to data using Papa Parse
    let parsedData = [];
    await new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          parsedData = results.data;
          resolve();
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          showSnackbar('Failed to parse CSV file', 'error');
          resolve();
        },
      });
    });

    // If parsing failed, abort
    if (!parsedData || parsedData.length === 0) {
      showSnackbar('No data found in CSV file', 'error');
      return;
    }

    // Now, send the file to the backend
    const formData = new FormData();
    formData.append('file', file);
    formData.append('campaign_id', selectedCampaign);
    formData.append('funnel_id', selectedFunnel || '');

    try {
      setLoading(true);
      const response = await api.post('/crm/leads/import_leads/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { success_count, failure_count, errors, message } = response.data;

      if (failure_count > 0) {
        const normalizedErrors = Array.isArray(errors) ? errors : [errors];

        // Map failed rows to their data
        const failedRows = normalizedErrors.map((errorItem) => {
          const { row, errors: rowErrors } = errorItem;
          const dataRow = parsedData[row - 2];

          let errorArray = [];

          if (Array.isArray(rowErrors)) {
            errorArray = rowErrors;
          } else if (typeof rowErrors === 'object' && rowErrors !== null) {
            Object.keys(rowErrors).forEach((field) => {
              const fieldErrors = rowErrors[field];
              if (Array.isArray(fieldErrors)) {
                errorArray = errorArray.concat(
                  fieldErrors.map((msg) => `${field}: ${msg}`)
                );
              } else {
                errorArray.push(`${field}: ${fieldErrors}`);
              }
            });
          } else if (typeof rowErrors === 'string') {
            errorArray.push(rowErrors);
          }

          return { ...dataRow, row, errors: errorArray };
        });

        setImportErrors(normalizedErrors);
        setFailedRowsData(failedRows);
        setShowErrorModal(true);

        showSnackbar(
          `${success_count} leads imported successfully. ${failure_count} leads failed to import.`,
          failure_count > 0 ? 'warning' : 'success'
        );
      } else {
        showSnackbar('All leads imported successfully', 'success');
      }

      fetchLeads();
    } catch (error) {
      console.error('Error importing leads:', error);
      showSnackbar('Failed to import leads', 'error');
    } finally {
      setLoading(false);
      // Reset the file input
      event.target.value = null;
    }
  };

  const handleMoreActionsClick = (event) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setMoreActionsAnchorEl(null);
  };

  const handleBulkActionsClick = (event) => {
    setBulkActionsAnchorEl(event.currentTarget);
  };

  const handleBulkActionsClose = () => {
    setBulkActionsAnchorEl(null);
    setAssignFunnelAnchorEl(null);
  };

  const handleAssignFunnelClick = (event) => {
    setAssignFunnelAnchorEl(event.currentTarget);
  };

  const handleAssignFunnelClose = () => {
    setAssignFunnelAnchorEl(null);
  };

  const handleActionsMenuOpen = (event, lead) => {
    setActionsAnchorEl(event.currentTarget);
    setCurrentLead(lead);
  };

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
    setCurrentLead(null);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Add the handler
  const handleDownloadTemplate = () => {
    if (!selectedCampaign) {
      showSnackbar('Please select a campaign to download the template', 'error');
      return;
    }

    api
      .get('/crm/leads/download_template/', {
        params: { campaign_id: selectedCampaign },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lead_import_template.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('Error downloading template:', error);
        showSnackbar('Failed to download template', 'error');
      });
  };

  const getColumns = () => {
    const baseColumns = [
      { id: 'lead_details', label: 'Lead Details', minWidth: 50, sortable: false },
      { id: 'first_name', label: 'First Name', minWidth: 100, sortable: true },
      { id: 'last_name', label: 'Last Name', minWidth: 100, sortable: true },
      { id: 'email', label: 'Email', minWidth: 170, sortable: true },
      { id: 'phone_number', label: 'Phone Number', minWidth: 130, sortable: true },
      { id: 'current_step', label: 'Stage', minWidth: 100, sortable: true },
      { id: 'status', label: 'Status', minWidth: 100, sortable: true },
      { id: 'assigned_to', label: 'Assigned To', minWidth: 130, sortable: true },
    ];

    const subclassColumns = [];

    if (campaignDetails && campaignDetails.campaign_model) {
      const leadType = campaignDetails.campaign_model.name;

      if (leadType === 'B2B') {
        subclassColumns.push(
          { id: 'company', label: 'Company', minWidth: 100, sortable: true },
          { id: 'job_title', label: 'Job Title', minWidth: 100, sortable: true },
          { id: 'industry', label: 'Industry', minWidth: 100, sortable: true },
          { id: 'company_size', label: 'Company Size', minWidth: 100, sortable: true },
          { id: 'annual_revenue', label: 'Annual Revenue', minWidth: 100, sortable: true }
        );
      } else if (leadType === 'D2C') {
        subclassColumns.push(
          { id: 'date_of_birth', label: 'Date of Birth', minWidth: 100, sortable: true },
          { id: 'age', label: 'Age', minWidth: 50, sortable: true },
          { id: 'gender', label: 'Gender', minWidth: 50, sortable: true },
          { id: 'marital_status', label: 'Marital Status', minWidth: 100, sortable: true }
        );
      }
    }

    const dynamicFieldColumns = (dynamicFields || []).map((field) => ({
      id: `dynamic_${field.api_name}`,
      label: field.name,
      minWidth: 100,
      sortable: true,
    }));    

    const endColumns = [{ id: 'actions', label: 'Actions', minWidth: 50, sortable: false }];

    return [...baseColumns, ...subclassColumns, ...dynamicFieldColumns, ...endColumns];
  };

  // Use useMemo to recompute columns when dependencies change
  const columns = useMemo(() => getColumns(), [campaignDetails, dynamicFields]);

  // Function to download CSV of failed rows
  const downloadFailedRowsCSV = () => {
    if (failedRowsData.length === 0) return;

    // Define the headers, including an 'Errors' column
    const headers = [...Object.keys(failedRowsData[0]).filter((key) => key !== 'errors'), 'Errors'];

    // Map the failedRowsData to include error messages
    const dataWithErrors = failedRowsData.map((row) => {
      const { row: rowNumber, errors, ...rest } = row;
      return { ...rest, Errors: errors.join('; ') };
    });

    // Convert to CSV using Papa Parse
    const csv = Papa.unparse({
      fields: headers,
      data: dataWithErrors,
    });

    // Trigger download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace('T', '_').slice(0, 15);
    link.setAttribute('download', `failed_leads_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // **Updated handleCellChange function**
  const handleCellChange = (leadId, updates, fieldDefinitionId = null) => {
    setLeads((prevLeads) =>
      prevLeads.map((lead) => {
        if (lead.id === leadId) {
          let updatedLead = { ...lead, ...updates };

          // Handle special fields
          if (updates.current_step !== undefined) {
            const selectedStep = funnelSteps.find((step) => step.id === updates.current_step);
            updatedLead.current_step_name = selectedStep ? selectedStep.name : null;
          }
          if (updates.status !== undefined) {
            const selectedStatus = leadStatuses.find((status) => status.id === updates.status);
            updatedLead.status_name = selectedStatus ? selectedStatus.name : null;
          }

          // Handle dynamic fields
          Object.keys(updates).forEach((key) => {
            if (key.startsWith('dynamic_')) {
              const apiName = key.replace('dynamic_', '');
              const fieldValues = updatedLead.field_values || [];

              if (!fieldDefinitionId) {
                const fieldDefinition = dynamicFields.find((field) => field.api_name === apiName);
                if (fieldDefinition) {
                  fieldDefinitionId = fieldDefinition.id;
                } else {
                  console.error(`Field definition not found for apiName: ${apiName}`);
                }
              }

              const existingFieldValueIndex = fieldValues.findIndex(
                (fv) => fv.field_api_name === apiName
              );
              if (existingFieldValueIndex >= 0) {
                fieldValues[existingFieldValueIndex] = {
                  ...fieldValues[existingFieldValueIndex],
                  value: updates[key],
                  field_definition: fieldDefinitionId,
                };
              } else {
                fieldValues.push({
                  field_api_name: apiName,
                  value: updates[key],
                  field_definition: fieldDefinitionId,
                });
              }
              updatedLead.field_values = fieldValues;
            }
          });

          return updatedLead;
        }
        return lead;
      })
    );
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.background.default, padding: 2 }}>
      {/* Selection Components */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Account Selection */}
        <Grid item xs={12} sm={6} md={4}>
          {accountsLoading ? (
            <CircularProgress />
          ) : (
            <FormControl fullWidth>
              <InputLabel>Select Account</InputLabel>
              <Select
                value={selectedAccount}
                onChange={handleAccountChange}
                label="Select Account"
              >
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={String(account.id)}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* Campaign Selection */}
        {selectedAccount && !accountsLoading && (
          <Grid item xs={12} sm={6} md={4}>
            {campaignsLoading ? (
              <CircularProgress />
            ) : (
              <FormControl fullWidth>
                <InputLabel>Select Campaign</InputLabel>
                <Select
                  value={selectedCampaign}
                  onChange={handleCampaignChange}
                  label="Select Campaign"
                >
                  {campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={String(campaign.id)}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        )}

        {/* Funnel Selection */}
        {selectedAccount && !accountsLoading && selectedCampaign && !campaignsLoading && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Select Funnel (Optional)</InputLabel>
              <Select
                value={selectedFunnel}
                onChange={handleFunnelChange}
                label="Select Funnel"
              >
                <MenuItem value="">All Funnels</MenuItem>
                {funnels.map((funnel) => (
                  <MenuItem key={funnel.id} value={String(funnel.id)}>
                    {funnel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {/* Search and Action Buttons */}
      <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
        {/* Search Input */}
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Search leads..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              width: '300px',
            }}
          />
        </Box>

        {/* Primary Action Buttons */}
        <Stack direction="row" spacing={1}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddRow}>
            Add Lead
          </Button>

          {/* Bulk Actions Button */}
          <Button
            variant="contained"
            onClick={handleBulkActionsClick}
            endIcon={<MoreVertIcon />}
            disabled={selectedLeads.length === 0 || bulkActionLoading}
          >
            Bulk Actions
          </Button>
          <BulkActionsMenu
            anchorEl={bulkActionsAnchorEl}
            open={Boolean(bulkActionsAnchorEl)}
            onClose={handleBulkActionsClose}
            funnels={funnels}
            handleBulkDelete={handleBulkDelete}
            handleAssignFunnelClick={handleAssignFunnelClick}
            bulkActionLoading={bulkActionLoading}
          />

          {/* Assign Funnel Submenu */}
          <Menu
            anchorEl={assignFunnelAnchorEl}
            open={Boolean(assignFunnelAnchorEl)}
            onClose={handleAssignFunnelClose}
          >
            {funnels.map((funnel) => (
              <MenuItem
                key={funnel.id}
                onClick={() => {
                  handleBulkAction('assign', funnel.id);
                  handleAssignFunnelClose();
                  handleBulkActionsClose();
                }}
                disabled={bulkActionLoading}
              >
                {bulkActionLoading ? <CircularProgress size={20} /> : funnel.name}
              </MenuItem>
            ))}
          </Menu>

          {/* More Actions Menu */}
          <Button
            variant="contained"
            onClick={handleMoreActionsClick}
            endIcon={<MoreVertIcon />}
          >
            More Actions
          </Button>
          <Menu
            anchorEl={moreActionsAnchorEl}
            open={Boolean(moreActionsAnchorEl)}
            onClose={handleMoreActionsClose}
          >
            <MenuItem onClick={handleExport} disabled={loading || !selectedCampaign}>
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export Leads</ListItemText>
            </MenuItem>
            <MenuItem onClick={triggerFileInput} disabled={loading || !selectedCampaign}>
              <ListItemIcon>
                <FileUploadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Import Leads</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDownloadTemplate} disabled={!selectedCampaign}>
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download Template</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>

        {/* Hidden File Input for Import Leads */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImport}
          accept=".csv"
        />
      </Stack>

      {selectedCampaign && (
        <>
          {/* Leads Table */}
          <LeadsTable
            leads={leads}
            columns={columns}
            loading={loading}
            orderBy={orderBy}
            order={order}
            handleRequestSort={handleRequestSort}
            handleCellClick={handleCellClick}
            handleCellChange={handleCellChange}
            editingCell={editingCell}
            navigate={navigate}
            handleActionsMenuOpen={handleActionsMenuOpen}
            selectedLeads={selectedLeads}
            handleLeadSelect={handleLeadSelect}
            phoneNumberRegex={phoneNumberRegex}
            emailRegex={emailRegex}
            leadStatuses={leadStatuses}
            campaignMembers={campaignMembers}
            funnelSteps={funnelSteps}
            dynamicFields={dynamicFields} // Pass dynamic fields
          />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalLeads}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </>
      )}

      {/* Actions Menu */}
      <Menu
        anchorEl={actionsAnchorEl}
        open={Boolean(actionsAnchorEl)}
        onClose={handleActionsMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            handleSave(currentLead.id);
            handleActionsMenuClose();
          }}
        >
          <ListItemIcon>
            <SaveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Save" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCancel();
            handleActionsMenuClose();
          }}
        >
          <ListItemIcon>
            <CancelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Cancel" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(currentLead.id);
            handleActionsMenuClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      {/* Bulk Delete Confirmation Dialog */}
      <Dialog
        open={bulkDeleteConfirmationOpen}
        onClose={() => setBulkDeleteConfirmationOpen(false)}
        aria-labelledby="bulk-delete-confirmation-dialog-title"
        aria-describedby="bulk-delete-confirmation-dialog-description"
      >
        <DialogTitle id="bulk-delete-confirmation-dialog-title">Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="bulk-delete-confirmation-dialog-description">
            Are you sure you want to delete {selectedLeads.length} lead(s)? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteConfirmationOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmBulkDelete} color="secondary" disabled={bulkActionLoading}>
            {bulkActionLoading ? <CircularProgress size={20} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Save Lead Dialog */}
      <Dialog
        open={openConfirmation}
        onClose={handleCancelSave}
        aria-labelledby="confirm-save-dialog-title"
        aria-describedby="confirm-save-dialog-description"
      >
        <DialogTitle id="confirm-save-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-save-dialog-description">
            The following issues were found:
            <ul>
              {warningMessage.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
            <div style={{ marginTop: '16px' }}>
              Are you sure you want to save the lead with these values?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSave} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSave} color="secondary">
            Proceed and Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Modal for Import Errors */}
      <ErrorModal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        failedRowsData={failedRowsData}
        downloadFailedRowsCSV={downloadFailedRowsCSV}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LeadsManagement;
