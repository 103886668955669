import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tabs,
  Tab,
  Box,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, ArrowBack as ArrowBackIcon, Edit as EditIcon, AssignmentInd as AssignmentIndIcon} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../services/authService';

const AccountSettings = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const [account, setAccount] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: '', role: 'Account Member', campaigns: []  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [accountData, setAccountData] = useState({ name: '', status: 'active' }); // Manage account form data
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // Dialog for delete confirmation

  // New state variables for managing campaigns
  const [selectedUser, setSelectedUser] = useState(null);
  const [userCampaignsDialogOpen, setUserCampaignsDialogOpen] = useState(false);
  const [userCampaigns, setUserCampaigns] = useState([]);


  useEffect(() => {
    fetchAccountData();
    fetchUsers();
    fetchCampaigns();
  }, [accountId]);

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchAccountData = async () => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/`);
      const { name, description, active } = response.data;

      // Set account data with the appropriate string value for the status
      setAccountData({
        name: name,
        description: description,
        status: active ? 'active' : 'inactive',  // Map boolean to 'active' or 'inactive'
      });
    } catch (error) {
      console.error('Error fetching account data:', error);
      showSnackbar('Error fetching account data', 'error');
    }
  };


  const handleUpdateAccount = async () => {
    try {
      // Convert 'active'/'inactive' back to boolean before sending to the backend
      const updatedAccountData = {
        ...accountData,
        active: accountData.status === 'active',
      };

      await api.put(`/crm/accounts/${accountId}/`, updatedAccountData);
      showSnackbar('Account updated successfully', 'success');
      fetchAccountData(); // Refresh the account data after update
    } catch (error) {
      console.error('Error updating account:', error);
      showSnackbar('Error updating account', 'error');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await api.delete(`/crm/accounts/${accountId}/`);
      showSnackbar('Account deleted successfully', 'success');
      navigate('/crm/accounts'); // Redirect to accounts list
    } catch (error) {
      console.error('Error deleting account:', error);
      showSnackbar('Error deleting account', 'error');
    }
  };

  const handleAddUser = async () => {
    try {
      const payload = {
        email: newUser.email,
        role: newUser.role,
        campaigns: newUser.campaigns,
      };
      await api.post(`/crm/accounts/${accountId}/add_user/`, payload);
      setNewUser({ email: '', role: 'Account Member', campaigns: [] });
      setUserDialogOpen(false);
      fetchUsers();
      showSnackbar('User added to account successfully', 'success');
    } catch (error) {
      console.error('Error adding user to account:', error);
      showSnackbar('Error adding user to account', 'error');
    }
  };

  const handleUpdateUserRole = async (userId, newRole) => {
    try {
      await api.put(`/crm/accounts/${accountId}/update_user_role/`, { user_id: userId, role: newRole });
      fetchUsers();
      showSnackbar('User role updated successfully', 'success');
    } catch (error) {
      console.error('Error updating user role:', error);
      showSnackbar('Error updating user role', 'error');
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      await api.delete(`/crm/accounts/${accountId}/remove_user/`, {
        data: { user_id: userId } // Pass the user_id in the request data
      });
      fetchUsers();
      showSnackbar('User removed from account successfully', 'success');
    } catch (error) {
      console.error('Error removing user from account:', error);
      showSnackbar('Error removing user from account', 'error');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/users/`);
      // Assuming your API returns { users: [...] }
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching account users:', error);
      showSnackbar('Error fetching account users', 'error');
    }
  };

  // Fetch all campaigns associated with the account
  const fetchCampaigns = async () => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      showSnackbar('Error fetching campaigns', 'error');
    }
  };

  // Handle opening the manage campaigns dialog
  const handleManageCampaigns = (user) => {
    setSelectedUser(user);
    // Get IDs of campaigns assigned to the user
    setUserCampaigns(user.campaign_access.map((c) => c.campaign_id));
    setUserCampaignsDialogOpen(true);
  };

  const handleCampaignCheckboxChange = (event, campaignId) => {
    if (event.target.checked) {
      setUserCampaigns([...userCampaigns, campaignId]);
    } else {
      setUserCampaigns(userCampaigns.filter((id) => id !== campaignId));
    }
  };


  const handleUpdateUserCampaigns = async () => {
    try {
      const currentCampaignIds = selectedUser.campaign_access.map((c) => c.campaign_id);
  
      // Campaigns to add (newly selected campaigns that the user does not already have access to)
      const campaignsToAdd = userCampaigns.filter(
        (campaignId) => !currentCampaignIds.includes(campaignId)
      );
  
      // Campaigns to remove (existing campaigns that are no longer selected)
      const campaignsToRemove = currentCampaignIds.filter(
        (campaignId) => !userCampaigns.includes(campaignId)
      );
  
      // Add campaigns
      for (const campaignId of campaignsToAdd) {
        await api.post(`/crm/accounts/${accountId}/add_user_to_campaign/`, {
          user_id: selectedUser.user.id,
          campaign_id: campaignId,
        });
      }
  
      // Remove campaigns
      for (const campaignId of campaignsToRemove) {
        await api.delete(`/crm/accounts/${accountId}/remove_user_from_campaign/`, {
          data: {
            user_id: selectedUser.user.id,
            campaign_id: campaignId,
          },
        });
      }
  
      // Close the dialog and show success message
      setUserCampaignsDialogOpen(false);
      showSnackbar('User campaigns updated successfully', 'success');
      fetchUsers(); // Refresh the user data
    } catch (error) {
      console.error('Error updating user campaigns:', error);
      showSnackbar('Error updating user campaigns', 'error');
    }
  };
  

  return (
    <Box sx={{ padding: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/crm/accounts')}
        sx={{ mb: 2 }}
      >
        Back to Accounts
      </Button>
      <Typography variant="h4" gutterBottom color="textPrimary">
        Account Settings: {accountData?.name}
      </Typography>
  
      {/* Tabs for different sections */}
      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
        <Tab label="General Information" />
        <Tab label="User Management" />
      </Tabs>
  
      <Box sx={{ padding: 2 }}>
        {/* Account Management */}
        {tabValue === 0 && (
          <>
            <Typography variant="h6" gutterBottom color="textPrimary">
              General Information
            </Typography>
            <Divider sx={{ my: 2 }} />
            <TextField
              label="Name"
              fullWidth
              value={accountData.name}
              onChange={(e) => setAccountData({ ...accountData, name: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Description"
              fullWidth
              value={accountData.description}
              onChange={(e) => setAccountData({ ...accountData, description: e.target.value })}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: !!accountData.description,  // Force label to shrink when there is a value
              }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={accountData.status}
                onChange={(e) => setAccountData({ ...accountData, status: e.target.value })}
                label="Status"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            
            {/* Buttons aligned on the same row */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 'auto' }}>
              {/* Update Account Button */}
              <Button
                variant="contained"
                onClick={handleUpdateAccount}
                sx={{
                  backgroundColor: 'primary',        // Blue background color for Update button
                  color: 'white',                 // White text color
                  ':hover': {                     // Styling on hover
                    backgroundColor: '#003366'    // Darker blue on hover
                  }
                }}
              >
                Update Account
              </Button>

              {/* Delete Account Button */}
              <Button
                variant="contained"
                onClick={() => setDeleteDialogOpen(true)}
                sx={{
                  backgroundColor: 'red',        // Red background color for Delete button
                  color: 'white',                // White text color
                  ':hover': {                    // Styling on hover
                    backgroundColor: '#cc0000'   // Darker red on hover
                  }
                }}
              >
                Delete Account
              </Button>
            </Box>
          </>
        )}
  
        <Box sx={{ padding: 2 }}>
        {tabValue === 1 && (
          <>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }} color="textPrimary">
              User Management
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Campaigns</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(users) && users.length > 0 ? (
                    users.map((userData) => (
                      <TableRow key={userData.user.id}>
                        <TableCell>{userData.user.email}</TableCell>
                        <TableCell>
                          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                            <Select
                              value={userData.account_membership.role.name}
                              onChange={(e) =>
                                handleUpdateUserRole(userData.user.id, e.target.value)
                              }
                            >
                              <MenuItem value="Account Admin">Account Admin</MenuItem>
                              <MenuItem value="Account Manager">Account Manager</MenuItem>
                              <MenuItem value="Account Member">Account Member</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          {userData.campaign_access && userData.campaign_access.length > 0 ? (
                            <Typography>
                              {userData.campaign_access
                                .map((campaignAccess) => campaignAccess.campaign_name ? campaignAccess.campaign_name : 'No Name')
                                .join(', ')} 
                            </Typography>
                          ) : (
                            <Typography>No Campaigns Assigned</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Assign Campaigns">
                            <IconButton onClick={() => handleManageCampaigns(userData)}>
                              <AssignmentIndIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove User">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveUser(userData.user.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No users found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setUserDialogOpen(true)}
              sx={{ mb: 2 }}
            >
              Add User to Account
            </Button>
          </>
        )}
        </Box>
      </Box>

      {/* Delete Account Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this account and all associated data?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={userDialogOpen} onClose={() => setUserDialogOpen(false)}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              label="Role"
            >
              <MenuItem value="Account Member">Account Member</MenuItem>
              <MenuItem value="Account Manager">Account Manager</MenuItem>
              <MenuItem value="Account Admin">Account Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Campaigns</InputLabel>
            <Select
              multiple
              value={newUser.campaigns}
              onChange={(e) => setNewUser({ ...newUser, campaigns: e.target.value })}
              label="Campaigns"
              renderValue={(selected) =>
                campaigns
                  .filter((campaign) => selected.includes(campaign.id))
                  .map((campaign) => campaign.name)
                  .join(', ')
              }
            >
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  <Checkbox checked={newUser.campaigns.includes(campaign.id)} />
                  <ListItemText primary={campaign.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddUser} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Manage Campaigns Dialog */}
      <Dialog
        open={userCampaignsDialogOpen}
        onClose={() => setUserCampaignsDialogOpen(false)}
      >
        <DialogTitle>Manage Campaigns for {selectedUser?.user?.email}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {campaigns.map((campaign) => (
                <FormControlLabel
                  key={campaign.id}
                  control={
                    <Checkbox
                      checked={userCampaigns.includes(campaign.id)}
                      onChange={(e) => handleCampaignCheckboxChange(e, campaign.id)}
                    />
                  }
                  label={campaign.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserCampaignsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleUpdateUserCampaigns} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>

  );
};

export default AccountSettings