// src/redux/snackbarSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  severity: 'info', // 'success', 'error', 'warning', 'info'
  open: false,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar(state, action) {
      state.message = action.payload.message;
      state.severity = action.payload.severity || 'info';
      state.open = true;
    },
    closeSnackbar(state) {
      state.open = false;
    },
  },
});

export const { showSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
