// src/pages/ToDoPage.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTasks, deleteTaskThunk } from '../redux/tasksSlice';
import {getScheduledReachOuts, deleteScheduledReachOutThunk,} from '../redux/scheduledReachOutsSlice';
import { Link } from 'react-router-dom';
import {
  Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, Table, TableHead, TableBody,
  TableRow, TableCell, IconButton, Tabs, Tab, Snackbar, Alert
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import CreateTaskDialog from '../components/CreateTaskDialog';
import EditTaskDialog from '../components/EditTaskDialog';
import CreateScheduledReachOutDialog from '../components/CreateScheduledReachOutDialog';
import EditScheduledReachOutDialog from '../components/EditScheduledReachOutDialog';
import { api } from '../services/authService'; // Import your API service
import { useTheme } from '@mui/material/styles';
import { format, utcToZonedTime } from 'date-fns-tz';

// Helper function to convert text to title case
const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const ToDoPage = () => {
  const theme = useTheme(); // Access the theme for consistent styling
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.tasks);
  const scheduledReachOuts = useSelector(
    (state) => state.scheduledReachOuts.scheduledReachOuts
  );

  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC'; // Get user's timezone

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);

  // State for accounts, campaigns, and selected values
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');

  const [editReachOutDialogOpen, setEditReachOutDialogOpen] = useState(false);
  const [reachOutToEdit, setReachOutToEdit] = useState(null);
  const [createReachOutDialogOpen, setCreateReachOutDialogOpen] = useState(false); // New state

  const [tabValue, setTabValue] = useState(0); // For keeping track of the selected tab

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchCampaigns(selectedAccount);
    } else {
      setCampaigns([]);
      setSelectedCampaign('');
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedCampaign) {
      // Fetch tasks for the selected campaign
      dispatch(getTasks(selectedCampaign));
      dispatch(getScheduledReachOuts(selectedCampaign));
    }
  }, [dispatch, selectedCampaign]);

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
      if (response.data.length > 0) {
        setSelectedAccount(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchCampaigns = async (accountId) => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const handleCreateTaskClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleEditClick = (task) => {
    setTaskToEdit(task);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setTaskToEdit(null);
  };

  const handleDeleteClick = (taskId) => {
    dispatch(deleteTaskThunk(taskId));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Scheduled ReachOut handlers
  const handleCreateReachOutClick = () => {
    setCreateReachOutDialogOpen(true);
  };

  const handleCreateReachOutDialogClose = () => {
    setCreateReachOutDialogOpen(false);
  };

  const handleEditReachOutClick = (reachOut) => {
    setReachOutToEdit(reachOut);
    setEditReachOutDialogOpen(true);
  };

  const handleEditReachOutDialogClose = () => {
    setEditReachOutDialogOpen(false);
    setReachOutToEdit(null);
  };

  const handleDeleteReachOutClick = (reachOutId) => {
    dispatch(deleteScheduledReachOutThunk(reachOutId));
  };

  const handleSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    dispatch(getScheduledReachOuts(selectedCampaign)); // Refresh the list
  };

  const handleError = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

   // Helper functions to format dates
   const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const zonedDate = utcToZonedTime(date, timezone);
    return format(zonedDate, 'yyyy-MM-dd', { timeZone: timezone });
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const zonedDate = utcToZonedTime(date, timezone);
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: timezone });
  };

  return (
    <Box>
      <Typography variant="h4" color="textPrimary">To-Do List</Typography>

      {/* Account Selection */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="account-select-label">Select Account</InputLabel>
        <Select
          labelId="account-select-label"
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          label="Select Account"
        >
          {accounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Campaign Selection */}
      <FormControl fullWidth margin="normal" disabled={!selectedAccount}>
        <InputLabel id="campaign-select-label">Select Campaign</InputLabel>
        <Select
          labelId="campaign-select-label"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          label="Select Campaign"
        >
          {campaigns.map((campaign) => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedCampaign && (
        <>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{ mt: 2 }}>
            <Tab label="General Tasks" />
            <Tab label="Scheduled Callbacks" />
          </Tabs>

          {tabValue === 0 && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateTaskClick}
                sx={{ my: 2 }}
              >
                Create Task
              </Button>
              {/* General Tasks Table */}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Assigned To</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task.id}>
                      <TableCell>{task.title}</TableCell>
                      <TableCell>{task.description}</TableCell>
                      <TableCell>{formatDateTime(task.due_date)}</TableCell>
                      <TableCell>{toTitleCase(task.priority)}</TableCell>
                      <TableCell>
                        {task.assigned_to?.first_name && task.assigned_to?.last_name
                          ? `${task.assigned_to.first_name} ${task.assigned_to.last_name}`
                          : 'Unassigned'}
                      </TableCell>
                      <TableCell>{toTitleCase(task.status)}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEditClick(task)}
                          color="primary"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(task.id)}
                          color="secondary"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <CreateTaskDialog
                open={dialogOpen}
                handleClose={handleDialogClose}
                campaignId={selectedCampaign}
                accountId={selectedAccount}
              />

              {taskToEdit && (
                <EditTaskDialog
                  open={editDialogOpen}
                  handleClose={handleEditDialogClose}
                  task={taskToEdit}
                  campaignId={selectedCampaign}
                  accountId={selectedAccount}
                />
              )}
            </>
          )}

          {tabValue === 1 && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateReachOutClick}
                sx={{ my: 2 }}
              >
                Add Scheduled Follow Up
              </Button>
              {/* Scheduled Callbacks Table */}
              {scheduledReachOuts.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Lead</TableCell>
                      <TableCell>Scheduled Time</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scheduledReachOuts.map((reachOut) => (
                      <TableRow key={reachOut.id}>
                        <TableCell>
                        {reachOut.lead ? (
                            <Link
                              to={`/crm/leads/${reachOut.lead}/details`}
                              style={{
                                color: theme.palette.primary.light,
                                textDecoration: 'none',
                                fontWeight: 500,
                              }}
                            >
                              {reachOut.lead_name}
                            </Link>
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell>{formatDateTime(reachOut.scheduled_date)}</TableCell>
                        <TableCell>{reachOut.reason}</TableCell>
                        <TableCell>{reachOut.description}</TableCell>
                        <TableCell>{reachOut.notes}</TableCell>
                        <TableCell>{toTitleCase(reachOut.status)}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleEditReachOutClick(reachOut)}
                            color="primary"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteReachOutClick(reachOut.id)}
                            color="secondary"
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="body1" align="center" 
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.h5.fontWeight,
                }}>
                  No scheduled follow-ups found!
                </Typography>
              )}

              <CreateScheduledReachOutDialog
                open={createReachOutDialogOpen}
                handleClose={handleCreateReachOutDialogClose}
                campaignId={selectedCampaign}
                accountId={selectedAccount}
                onSuccess={handleSuccess}
                onError={handleError}
              />

              {reachOutToEdit && (
                <EditScheduledReachOutDialog
                  open={editReachOutDialogOpen}
                  handleClose={handleEditReachOutDialogClose}
                  reachOut={reachOutToEdit}
                  campaign={selectedCampaign}
                />
              )}
            </>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert 
              onClose={() => setSnackbarOpen(false)} 
              severity={snackbarSeverity}
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </Box>
  );
};

export default ToDoPage;
