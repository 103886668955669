import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTasks, createTask, updateTask, deleteTask } from '../services/taskService';

// Thunks for asynchronous actions

// Fetch tasks for a specific campaign
export const getTasks = createAsyncThunk(
  'tasks/getTasks',
  async (campaignId, { rejectWithValue }) => {
    try {
      const response = await fetchTasks(campaignId);
      return response.data;
    } catch (err) {
      // Return the error message to be handled in the reducer
      return rejectWithValue(err.response.data || 'Error fetching tasks');
    }
  }
);

// Create a new task
export const createTaskThunk = createAsyncThunk(
  'tasks/createTask',
  async (taskData, { rejectWithValue }) => {
    try {
      const response = await createTask(taskData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data || 'Error creating task');
    }
  }
);

export const updateTaskThunk = createAsyncThunk(
  'tasks/updateTask',
  async ({ taskId, taskData }, { rejectWithValue }) => {
    try {
      const response = await updateTask(taskId, taskData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error updating task');
    }
  }
);

export const deleteTaskThunk = createAsyncThunk(
  'tasks/deleteTask',
  async (taskId, { rejectWithValue }) => {
    try {
      await deleteTask(taskId);
      return taskId;
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error deleting task');
    }
  }
);

// The tasks slice
const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    // Synchronous reducers can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle getTasks actions
      .addCase(getTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasks = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle createTaskThunk actions
      .addCase(createTaskThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createTaskThunk.fulfilled, (state, action) => {
        state.tasks.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createTaskThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle updateTaskThunk actions
      .addCase(updateTaskThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTaskThunk.fulfilled, (state, action) => {
        const updatedTask = action.payload;
        const index = state.tasks.findIndex((task) => task.id === updatedTask.id);
        if (index !== -1) {
          state.tasks[index] = updatedTask;
        }
        state.status = 'succeeded';
      })
      .addCase(updateTaskThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle deleteTaskThunk actions
      .addCase(deleteTaskThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteTaskThunk.fulfilled, (state, action) => {
        const taskId = action.payload;
        state.tasks = state.tasks.filter((task) => task.id !== taskId);
        state.status = 'succeeded';
      })
      .addCase(deleteTaskThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default tasksSlice.reducer;
