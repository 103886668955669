// src/pages/ActivateAccount.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Alert,
  Button,
} from '@mui/material';
import { apiWithoutAuth } from '../services/authService';

const ActivateAccount = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [activationStatus, setActivationStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const activateAccount = async () => {
    try {
        const response = await apiWithoutAuth.post(
        `/accounts/activate/${uid}/${token}/`
        );
        if (response.status === 200) {
        // Redirect to set password page
        navigate(`/set-password?uid=${uid}&token=${token}`);
        } else {
        setActivationStatus('error');
        setErrorMessage(response.data.error || 'Activation failed.');
        }
      } catch (error) {
        console.error('Activation error:', error);
        setActivationStatus('error');
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.error || 'Activation failed.');
        } else {
          setErrorMessage('Activation failed. Please try again.');
        }
      }
    };

    activateAccount();
  }, [uid, token]);

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {activationStatus === 'loading' && (
          <>
            <Typography component="h1" variant="h5">
              Activating your account...
            </Typography>
            <CircularProgress sx={{ mt: 2 }} />
          </>
        )}
        {activationStatus === 'success' && (
          <>
            <Typography component="h1" variant="h5">
              Account Activated Successfully!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Your account has been activated. You can now log in.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={handleLoginRedirect}
            >
              Go to Login
            </Button>
          </>
        )}
        {activationStatus === 'error' && (
          <>
            <Typography component="h1" variant="h5">
              Activation Failed
            </Typography>
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={handleLoginRedirect}
            >
              Go to Login
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ActivateAccount;
