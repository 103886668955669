// src/services/dashboardService.js
import { api } from './authService';

export const fetchDashboardData = async () => {
  try {
    const response = await api.get('crm/dashboard/');
    return response.data;
  } catch (error) {
    throw error;
  }
};
