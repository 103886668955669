// src/components/IntegrationsTable.js

import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const IntegrationsTable = ({ data }) => {
  const theme = useTheme();

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.background.paper }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: theme.palette.text.primary }}>Name</TableCell>
            <TableCell sx={{ color: theme.palette.text.primary }}>Type</TableCell>
            <TableCell sx={{ color: theme.palette.text.primary }}>Category</TableCell>
            <TableCell sx={{ color: theme.palette.text.primary }}>Status</TableCell>
            <TableCell sx={{ color: theme.palette.text.primary }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((integration) => (
            <TableRow key={integration.id}>
              <TableCell sx={{ color: theme.palette.text.secondary }}>{integration.name}</TableCell>
              <TableCell sx={{ color: theme.palette.text.secondary }}>{integration.type}</TableCell>
              <TableCell sx={{ color: theme.palette.text.secondary }}>
                {integration.category}
              </TableCell>
              <TableCell sx={{ color: theme.palette.text.secondary }}>
                {integration.status}
              </TableCell>
              <TableCell>
                <IconButton color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton color="secondary">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IntegrationsTable;
