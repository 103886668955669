// src/pages/NotificationRuleForm.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { api } from '../services/authService';
import theme from '../theme/theme';

const NotificationRuleForm = ({ onRuleCreated, existingRule }) => {
  const [name, setName] = useState(existingRule ? existingRule.name : '');
  const [criteriaList, setCriteriaList] = useState(
    existingRule ? parseCriteria(existingRule.criteria) : [{ field: '', operator: '', value: '' }]
  );
  const [logicalOperator, setLogicalOperator] = useState(
    existingRule ? existingRule.logical_operator : 'AND'
  );

  const fields = [
    { label: 'Age', value: 'age', type: 'number' },
    { label: 'Status', value: 'status', type: 'select', options: ['New', 'Contacted', 'Waiting for Response', 'Closed'] },
    { label: 'Last Contact Date', value: 'last_contact_date', type: 'date' },
    // Add more fields as needed
  ];

  const operators = [
    { label: 'Equals', value: 'eq' },
    { label: 'Not Equal', value: 'neq' },
    { label: 'Greater Than', value: 'gt' },
    { label: 'Less Than', value: 'lt' },
    { label: 'Greater Than or Equal', value: 'gte' },
    { label: 'Less Than or Equal', value: 'lte' },
    { label: 'Contains', value: 'contains' },
  ];

  useEffect(() => {
    if (existingRule) {
      setName(existingRule.name);
      setCriteriaList(parseCriteria(existingRule.criteria));
      setLogicalOperator(existingRule.logical_operator || 'AND');
    }
  }, [existingRule]);

  const parseCriteria = (criteria) => {
    return Object.entries(criteria).map(([field, condition]) => ({
      field,
      operator: condition.operator,
      value: condition.value,
    }));
  };

  const handleCriteriaChange = (index, key, value) => {
    const newCriteriaList = [...criteriaList];
    newCriteriaList[index][key] = value;
    setCriteriaList(newCriteriaList);
  };

  const handleAddCriteria = () => {
    setCriteriaList([...criteriaList, { field: '', operator: '', value: '' }]);
  };

  const handleRemoveCriteria = (index) => {
    const newCriteriaList = [...criteriaList];
    newCriteriaList.splice(index, 1);
    setCriteriaList(newCriteriaList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const criteria = criteriaList.reduce((acc, item) => {
        acc[item.field] = {
          operator: item.operator,
          value: item.value,
        };
        return acc;
      }, {});
      const payload = { name, criteria, logical_operator: logicalOperator };
      if (existingRule) {
        await api.put(`/crm/custom-notification-rules/${existingRule.id}/`, payload);
      } else {
        await api.post('/crm/custom-notification-rules/', payload);
      }
      if (onRuleCreated) {
        onRuleCreated();
      }
      setName('');
      setCriteriaList([{ field: '', operator: '', value: '' }]);
      setLogicalOperator('AND');
    } catch (error) {
      console.error('Error saving custom notification rule:', error);
    }
  };

  const getValueInput = (fieldValue, value, onChange) => {
    const field = fields.find((f) => f.value === fieldValue);
    if (!field) {
      return (
        <TextField
          label="Value"
          value={value}
          onChange={onChange}
          fullWidth
          required
        />
      );
    }
    switch (field.type) {
      case 'number':
        return (
          <TextField
            label="Value"
            value={value}
            onChange={onChange}
            fullWidth
            required
            type="number"
          />
        );
      case 'date':
        return (
          <TextField
            label="Value"
            value={value}
            onChange={onChange}
            fullWidth
            required
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      case 'select':
        return (
          <FormControl fullWidth required>
            <InputLabel>Value</InputLabel>
            <Select value={value} onChange={onChange} label="Value">
              {field.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return (
          <TextField
            label="Value"
            value={value}
            onChange={onChange}
            fullWidth
            required
          />
        );
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        p: 2,
      }}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          label="Rule Name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          margin="normal"
          sx={{ input: { color: theme.palette.text.primary } }}
        />

        <FormControl fullWidth required sx={{ mt: 2 }}>
          <InputLabel>Combine Criteria With</InputLabel>
          <Select
            value={logicalOperator}
            onChange={(e) => setLogicalOperator(e.target.value)}
            label="Combine Criteria With"
          >
            <MenuItem value="AND">AND</MenuItem>
            <MenuItem value="OR">OR</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Criteria
        </Typography>
        {criteriaList.map((criteria, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth required>
                <InputLabel>Field</InputLabel>
                <Select
                  value={criteria.field}
                  onChange={(e) =>
                    handleCriteriaChange(index, 'field', e.target.value)
                  }
                  label="Field"
                >
                  {fields.map((field) => (
                    <MenuItem key={field.value} value={field.value}>
                      {field.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth required>
                <InputLabel>Operator</InputLabel>
                <Select
                  value={criteria.operator}
                  onChange={(e) =>
                    handleCriteriaChange(index, 'operator', e.target.value)
                  }
                  label="Operator"
                >
                  {operators.map((operator) => (
                    <MenuItem key={operator.value} value={operator.value}>
                      {operator.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              {getValueInput(
                criteria.field,
                criteria.value,
                (e) => handleCriteriaChange(index, 'value', e.target.value)
              )}
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconButton
                onClick={() => handleRemoveCriteria(index)}
                disabled={criteriaList.length === 1}
                color="error"
              >
                <RemoveCircleOutline />
              </IconButton>
              {index === criteriaList.length - 1 && (
                <IconButton onClick={handleAddCriteria} color="primary">
                  <AddCircleOutline />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            mt: 3,
            fontWeight: 700,
            textTransform: 'none',
            padding: '12px 24px',
            borderRadius: '8px',
            fontSize: '16px',
          }}
        >
          {existingRule ? 'Update Rule' : 'Create Rule'}
        </Button>
      </form>
    </Box>
  );
};

export default NotificationRuleForm;
