// src/pages/NotificationRulesPage.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { api } from '../services/authService';
import NotificationRuleForm from '../components/NotificationRuleForm';
import theme from '../theme/theme';

const NotificationRulesPage = () => {
  const [rules, setRules] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);

  const fetchRules = async () => {
    try {
      const response = await api.get('/crm/custom-notification-rules/');
      setRules(response.data);
    } catch (error) {
      console.error('Error fetching notification rules:', error);
    }
  };

  useEffect(() => {
    fetchRules();
  }, []);

  const handleRuleCreated = () => {
    fetchRules();
    setOpenDialog(false);
    setCurrentRule(null);
  };

  const handleOpenDialog = () => {
    setCurrentRule(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentRule(null);
  };

  const handleEditRule = (rule) => {
    setCurrentRule(rule);
    setOpenDialog(true);
  };

  const handleDeleteRule = async (ruleId) => {
    if (window.confirm('Are you sure you want to delete this rule?')) {
      try {
        await api.delete(`/custom-notification-rules/${ruleId}/`);
        fetchRules();
      } catch (error) {
        console.error('Error deleting notification rule:', error);
      }
    }
  };

  const formatCriteria = (criteria, logicalOperator) => {
    const conditions = Object.entries(criteria).map(([field, condition]) => {
      const operatorMap = {
        'eq': 'equals',
        'neq': 'does not equal',
        'gt': 'greater than',
        'lt': 'less than',
        'gte': 'greater than or equal to',
        'lte': 'less than or equal to',
        'contains': 'contains',
      };
      const operatorText = operatorMap[condition.operator] || condition.operator;
      return `${field} ${operatorText} ${condition.value}`;
    });
    return conditions.join(` ${logicalOperator} `);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Custom Notification Rules
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        sx={{ mb: 2 }}
      >
        Create New Rule
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{currentRule ? 'Edit Custom Notification Rule' : 'Create Custom Notification Rule'}</DialogTitle>
        <DialogContent>
          <NotificationRuleForm onRuleCreated={handleRuleCreated} existingRule={currentRule} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rule Name</TableCell>
              <TableCell>Criteria</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((rule) => (
              <TableRow key={rule.id}>
                <TableCell>{rule.name}</TableCell>
                <TableCell>{formatCriteria(rule.criteria, rule.logical_operator)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditRule(rule)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteRule(rule.id)}
                    sx={{ ml: 1 }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default NotificationRulesPage;
