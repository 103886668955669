// src/pages/Profile.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Container, 
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { setCredentials, updateUser } from '../redux/authSlice';
import { api } from '../services/authService';
import { styled } from '@mui/system';
import GoogleIntegration from '../components/GoogleIntegration';
import moment from 'moment-timezone';


const StyledSnackbar = styled(Snackbar)(({ severity }) => ({
  '& .MuiPaper-root': {
    backgroundColor: severity === 'error' ? 'red' : 'inherit',
    color: severity === 'error' ? 'white' : 'inherit',
  },
}));

const Profile = () => {
  const user = useSelector(state => state.auth.user);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  const [isUserLoaded, setIsUserLoaded] = useState(false); // Loading state for user
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [timezone, setTimezone] = useState('UTC'); // Add timezone state
  const [timezones, setTimezones] = useState([]); // State to hold timezone options
  
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);


  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || '');
      setLastName(user.last_name || '');
      setEmail(user.email || '');
      setPhone(user.phone_number || '');
      setTimezone(user.timezone || 'UTC'); 
      setIsUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    // Fetch timezones from moment-timezone
    const tzNames = moment.tz.names();
    setTimezones(tzNames);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleGeneralInfoSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    // Check if the phone number is valid
    const isPhoneNumberValid = phoneNumberRegex.test(phone || '');

    // Check if the email is valid
    const isEmailValid = emailRegex.test(email || '');

    if (!isPhoneNumberValid) {
      showSnackbar('Invalid phone number format. Please use XXX-XXX-XXXX.', 'error');
      setIsLoading(false);
      return; // Prevent save if the phone number is invalid
    }

    if (!isEmailValid) {
      showSnackbar('Invalid email address format. Please provide a valid email.', 'error');
      setIsLoading(false);
      return; // Prevent save if the email is invalid
    }

    try {
      const updatedData = { 
        first_name: firstName, 
        last_name: lastName, 
        email, 
        phone_number: phone, 
        timezone };
      const response = await api.put('/accounts/users/me/', updatedData);

      dispatch(setCredentials({ 
        user: response.data.user, 
        token: response.data.tokens.access  // Update token if received
      }));

      // Update user in Redux and localStorage
      dispatch(updateUser({ user: response.data }));

      localStorage.setItem('token', response.data.tokens.access);

      setSuccess('Profile updated successfully');
    } catch (err) {
      setError('An error occurred while updating profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);
  
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await api.post('/accounts/users/change-password/', {
        current_password: currentPassword,
        new_password: newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,  // Include the token in the headers
        }
      });
  
      setSuccess('Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('An error occurred while updating password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };  

  if (!isUserLoaded) {
    return <CircularProgress />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          User Profile
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
            {success}
          </Alert>
        )}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="profile tabs">
            <Tab label="General Info" />
            <Tab label="Security" />
            <Tab label="Integrations" />
          </Tabs>
        </Box>
        {activeTab === 0 && (
          <Box component="form" onSubmit={handleGeneralInfoSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Phone Number"
              name="phone"
              autoComplete="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={isLoading}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="timezone-select-label">Timezone</InputLabel>
              <Select
                labelId="timezone-select-label"
                id="timezone-select"
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                label="Timezone"
                disabled={isLoading}
              >
                {timezones.map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Update Profile'}
            </Button>
          </Box>
        )}
        {activeTab === 1 && (
          <Box component="form" onSubmit={handlePasswordSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="currentPassword"
              label="Current Password"
              type="password"
              id="currentPassword"
              autoComplete="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Change Password'}
            </Button>
          </Box>
        )}

        {/* User Integrations Page */}
        {activeTab === 2 && (
          <Box sx={{ mt: 3, width: '100%' }}>
            {/* <Typography variant="h6" color='white'>Google Email & Calendar</Typography> */}
            <GoogleIntegration />
          </Box>
        )}

        <StyledSnackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Box>
    </Container>
  );
};

export default Profile;