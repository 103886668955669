import React from 'react';
import LeadsManagement from '../pages/LeadsManagement';

const LeadsList = () => {
  return (
    <div>
      <h1 style={{color: "white"}}>Leads</h1>
      <LeadsManagement />
    </div>
  );
};

export default LeadsList;