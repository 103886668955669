// src/pages/IntakeManagement.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  FileDownload as FileDownloadIcon,
  FileUpload as FileUploadIcon
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { api } from '../services/authService';

const IntakeManagement = ({ campaignId }) => {
  // State Management
  const [intakeSections, setIntakeSections] = useState([]);
  const [newSectionName, setNewSectionName] = useState('');
  const [newField, setNewField] = useState({
    name: '',
    intake_question: '',
    api_name: '',
    field_type: 'text',
    required: false,
    dropdown_options: [],
  });
  const [sectionDialogOpen, setSectionDialogOpen] = useState(false);
  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);
  const [editSectionDialogOpen, setEditSectionDialogOpen] = useState(false);
  const [sectionEditId, setSectionEditId] = useState(null);
  const [editSectionName, setEditSectionName] = useState('');
  const [newDropdownOption, setNewDropdownOption] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editingOption, setEditingOption] = useState('');
  const [editField, setEditField] = useState(null);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [confirmDeleteFieldDialogOpen, setConfirmDeleteFieldDialogOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const fileInputRef = useRef(null);
  const [currentSectionId, setCurrentSectionId] = useState(null); // Added missing state

  // Helper function to generate api_name from name
  const generateApiName = (name) => {
    return name
      .trim() // Remove leading and trailing whitespace
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .replace(/[^a-z0-9_]/g, ''); // Remove non-alphanumeric characters except underscores
  };

  // Fetch intake sections on component load
  useEffect(() => {
    fetchIntakeSections();
  }, [campaignId]);

  // Fetch all intake sections related to the campaign
  const fetchIntakeSections = async () => {
    try {
      const response = await api.get(`/crm/intake_sections/?campaign_id=${campaignId}`);
      setIntakeSections(response.data); // Set sections state
    } catch (error) {
      console.error('Error fetching intake sections:', error);
      showSnackbar('Error fetching intake sections', 'error');
    }
  };

  // Handle drag and drop for reordering sections and fields
  const onDragEnd = async (result) => {
    const { source, destination, type } = result;
    if (!destination) return; // No movement, return early

    if (type === 'section') {
      // Reordering sections
      const reorderedSections = Array.from(intakeSections);
      const [movedSection] = reorderedSections.splice(source.index, 1);
      reorderedSections.splice(destination.index, 0, movedSection);
      setIntakeSections(reorderedSections);

      // Persist reordered sections to the backend
      try {
        await api.post('/crm/intake_sections/reorder_sections/', {
          sections: reorderedSections.map((section, index) => ({
            id: section.id,
            order: index
          }))
        });
        showSnackbar('Sections reordered successfully', 'success');
      } catch (error) {
        console.error('Error saving reordered sections:', error);
        showSnackbar('Failed to save section order', 'error');
      }
    } else if (type === 'field') {
      // Reordering fields within or across sections
      const [sourceSectionPrefix, sourceSectionId] = source.droppableId.split('-');
      const [destSectionPrefix, destSectionId] = destination.droppableId.split('-');

      if (sourceSectionId !== destSectionId) {
        // Moving field from one section to another
        const sourceSectionIndex = intakeSections.findIndex(sec => sec.id.toString() === sourceSectionId);
        const destSectionIndex = intakeSections.findIndex(sec => sec.id.toString() === destSectionId);

        const sourceFields = Array.from(intakeSections[sourceSectionIndex].intake_fields);
        const [movedField] = sourceFields.splice(source.index, 1);
        const destFields = Array.from(intakeSections[destSectionIndex].intake_fields);
        destFields.splice(destination.index, 0, movedField);

        const updatedSections = Array.from(intakeSections);
        updatedSections[sourceSectionIndex].intake_fields = sourceFields;
        updatedSections[destSectionIndex].intake_fields = destFields;
        setIntakeSections(updatedSections);

        // Persist reordered fields to the backend
        try {
          // Update source section fields order
          await api.post('/crm/intake_fields/reorder_fields/', {
            fields: sourceFields.map((field, index) => ({
              id: field.id,
              order: index,
              section_id: sourceSectionId
            }))
          });

          // Update destination section fields order
          await api.post('/crm/intake_fields/reorder_fields/', {
            fields: destFields.map((field, index) => ({
              id: field.id,
              order: index,
              section_id: destSectionId
            }))
          });

          showSnackbar('Fields reordered successfully', 'success');
        } catch (error) {
          console.error('Error saving reordered fields:', error);
          showSnackbar('Failed to save field order', 'error');
        }
      } else {
        // Reordering fields within the same section
        const sectionIndex = intakeSections.findIndex(sec => sec.id.toString() === sourceSectionId);
        const reorderedFields = Array.from(intakeSections[sectionIndex].intake_fields);
        const [movedField] = reorderedFields.splice(source.index, 1);
        reorderedFields.splice(destination.index, 0, movedField);

        const updatedSections = Array.from(intakeSections);
        updatedSections[sectionIndex].intake_fields = reorderedFields;
        setIntakeSections(updatedSections);

        // Persist reordered fields to the backend
        try {
          await api.post('/crm/intake_fields/reorder_fields/', {
            fields: reorderedFields.map((field, index) => ({
              id: field.id,
              order: index,
              section_id: sourceSectionId
            }))
          });
          showSnackbar('Fields reordered successfully', 'success');
        } catch (error) {
          console.error('Error saving reordered fields:', error);
          showSnackbar('Failed to save field order', 'error');
        }
      }
    }
  };

  // Handle adding a new section
  const handleAddSection = async () => {
    try {
      const response = await api.post(`/crm/intake_sections/`, { name: newSectionName, campaign_id: campaignId });
      fetchIntakeSections(); // Refresh intake sections
      setSectionDialogOpen(false); // Close dialog
      setNewSectionName(''); // Reset input
      showSnackbar('Section added successfully', 'success');
    } catch (error) {
      console.error('Error adding section:', error);
      showSnackbar('Failed to add section', 'error');
    }
  };

  // Handle editing a section name
  const handleEditSection = async () => {
    try {
      await api.put(`/crm/intake_sections/${sectionEditId}/`, { name: editSectionName });
      fetchIntakeSections(); // Refresh intake sections
      setEditSectionDialogOpen(false); // Close dialog
      setEditSectionName(''); // Reset input
      showSnackbar('Section updated successfully', 'success');
    } catch (error) {
      console.error('Error editing section:', error);
      showSnackbar('Failed to update section', 'error');
    }
  };

  const openConfirmDeleteDialog = (sectionId) => {
    setSectionToDelete(sectionId);
    setConfirmDeleteDialogOpen(true);
  };

  const handleConfirmDeleteSection = async () => {
    try {
      await api.delete(`/crm/intake_sections/${sectionToDelete}/`);
      fetchIntakeSections();
      setConfirmDeleteDialogOpen(false);
      showSnackbar('Section deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting section:', error);
      showSnackbar('Failed to delete section', 'error');
    }
  };

  const openConfirmDeleteFieldDialog = (fieldId) => {
    setFieldToDelete(fieldId);
    setConfirmDeleteFieldDialogOpen(true);
  };

  const handleConfirmDeleteField = async () => {
    try {
      await api.delete(`/crm/intake_fields/${fieldToDelete}/`);
      fetchIntakeSections(); // Refresh intake sections to reflect the deleted field
      setConfirmDeleteFieldDialogOpen(false); // Close the dialog
      showSnackbar('Field deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting field:', error);
      showSnackbar('Failed to delete field', 'error');
    }
  };

  // Handle adding a new field
  const handleAddField = async () => {
    try {
      const payload = {
        ...newField,
        section_id: currentSectionId,
        api_name: generateApiName(newField.name), // Automatically generate api_name
      };
      if (newField.field_type !== 'dropdown') {
        payload.dropdown_options = null;
      }
      await api.post(`/crm/intake_fields/`, payload);
      fetchIntakeSections(); // Refresh intake sections to show the new field
      setFieldDialogOpen(false); // Close dialog
      setNewField({
        name: '',
        intake_question: '',
        api_name: '',
        field_type: 'text',
        required: false,
        dropdown_options: [],
      }); // Reset field input
      showSnackbar('Field added successfully', 'success');
    } catch (error) {
      console.error('Error adding field:', error);
      showSnackbar('Failed to add field', 'error');
    }
  };

  // Handle editing a field
  const handleEditField = (field) => {
    setEditField(field); // Store the field being edited
    setNewField({
      name: field.name,
      intake_question: field.intake_question || '',
      api_name: generateApiName(field.name), // Automatically generate api_name
      field_type: field.field_type,
      required: field.required,
      dropdown_options: field.field_type === 'dropdown'
        ? Array.isArray(field.dropdown_options)
          ? field.dropdown_options
          : typeof field.dropdown_options === 'string'
            ? field.dropdown_options.split(',').map((option) => option.trim())
            : []
        : [],
    });
    setNewDropdownOption('');
    setEditIndex(null);
    setEditingOption('');
    setFieldDialogOpen(true);
  };

  // Handle saving edits to an existing field
  const handleSaveFieldChanges = async () => {
    try {
      const payload = {
        ...newField,
        api_name: generateApiName(newField.name), // Automatically generate api_name
      };
      if (newField.field_type !== 'dropdown') {
        payload.dropdown_options = null;
      }
      await api.put(`/crm/intake_fields/${editField.id}/`, payload);
      fetchIntakeSections(); // Refresh intake sections to show the updated field
      setFieldDialogOpen(false); // Close dialog
      setEditField(null); // Clear edit mode
      setNewField({
        name: '',
        intake_question: '',
        api_name: '',
        field_type: 'text',
        required: false,
        dropdown_options: [],
      }); // Reset field input
      showSnackbar('Field updated successfully', 'success');
    } catch (error) {
      console.error('Error updating field:', error);
      showSnackbar('Failed to update field', 'error');
    }
  };

  // Open the field dialog and set the current section ID
  const openFieldDialog = (sectionId) => {
    setCurrentSectionId(sectionId);
    setNewField({
      name: '',
      intake_question: '',
      api_name: '',
      field_type: 'text',
      required: false,
      dropdown_options: [],
    });
    setNewDropdownOption('');
    setEditIndex(null);
    setEditingOption('');
    setEditField(null);
    setFieldDialogOpen(true);
  };

  // Open the edit section dialog and set the current section ID and name
  const openEditSectionDialog = (sectionId, sectionName) => {
    setSectionEditId(sectionId);
    setEditSectionName(sectionName);
    setEditSectionDialogOpen(true);
  };

  // Dropdown Option Handlers
  const handleAddDropdownOption = () => {
    if (!newDropdownOption.trim()) return;
    setNewField((prev) => ({
      ...prev,
      dropdown_options: [...prev.dropdown_options, newDropdownOption.trim()],
    }));
    setNewDropdownOption(''); // Clear input after adding
  };

  const handleRemoveDropdownOption = (index) => {
    const updatedOptions = newField.dropdown_options.filter((_, i) => i !== index);
    setNewField((prev) => ({ ...prev, dropdown_options: updatedOptions }));
  };

  const startEditDropdownOption = (index) => {
    setEditIndex(index);
    setEditingOption(newField.dropdown_options[index]);
  };

  const saveDropdownOption = () => {
    const updatedOptions = [...newField.dropdown_options];
    updatedOptions[editIndex] = editingOption;
    setNewField((prev) => ({ ...prev, dropdown_options: updatedOptions }));
    setEditIndex(null);
    setEditingOption('');
  };

  const cancelEditDropdownOption = () => {
    setEditIndex(null);
    setEditingOption('');
  };

  // Handler for Download Template
  const handleDownloadTemplate = async () => {
    if (!campaignId) {
      showSnackbar('Campaign ID is required to download the template', 'error');
      return;
    }
    setLoading(true);
    try {
      const response = await api.get('/crm/intake_sections/download_template/', {
        params: { campaign_id: campaignId },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'intake_template.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      showSnackbar('Template downloaded successfully', 'success');
    } catch (error) {
      console.error('Error downloading template:', error);
      showSnackbar('Failed to download template', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Handler for Import
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImportIntake = async (event) => {
    const file = event.target.files[0];
    event.target.value = null; // Reset the input value
    if (!file) {
      showSnackbar('No file selected', 'error');
      return;
    }

    if (!file.name.endsWith('.csv')) {
      showSnackbar('Please upload a CSV file', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('campaign_id', campaignId);

    try {
      setLoading(true);
      await api.post('/crm/intake_sections/import_intake/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      showSnackbar('Intake sections and fields imported successfully', 'success');
      fetchIntakeSections(); // Refresh the intake sections
    } catch (error) {
      console.error('Error importing intake:', error);
      showSnackbar('Failed to import intake', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Snackbar functions
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box>
      {/* Buttons for Add Section, Download Template, and Import */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button onClick={() => setSectionDialogOpen(true)} variant="contained">
          Add New Section
        </Button>
        <Button
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={handleDownloadTemplate}
        >
          Download Template
        </Button>
        <Button
          variant="contained"
          startIcon={<FileUploadIcon />}
          onClick={triggerFileInput}
        >
          Import
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImportIntake}
          accept=".csv"
        />
      </Box>

      {/* Drag-and-Drop Context for Intake Sections and Fields */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections" type="section">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {intakeSections.map((section, sectionIndex) => (
                <Draggable key={section.id} draggableId={`section-${section.id}`} index={sectionIndex}>
                  {(provided) => (
                    <Card
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      sx={{ mb: 3, position: 'relative', pb: 8 }}
                    >
                      {/* Top right position for Add Field button */}
                      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                        <Button onClick={() => openFieldDialog(section.id)} variant="contained">
                          Add Field
                        </Button>
                      </Box>

                      <CardContent>
                        {/* Drag handle applied only to the section title */}
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'bold', cursor: 'grab' }}
                          {...provided.dragHandleProps} // Apply drag handle to the title
                        >
                          {section.name}
                        </Typography>

                        {/* Drag-and-Drop Context for Intake Fields */}
                        <Droppable droppableId={`section-${section.id}`} type="field">
                          {(provided) => (
                            <List {...provided.droppableProps} ref={provided.innerRef}>
                              {section.intake_fields && section.intake_fields.length > 0 ? (
                                section.intake_fields.map((field, fieldIndex) => (
                                  <Draggable key={field.id} draggableId={`field-${field.id}`} index={fieldIndex}>
                                    {(provided) => (
                                      <ListItem
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        sx={{ borderBottom: '1px solid #eee', cursor: 'grab' }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography sx={{ fontWeight: '500' }}>
                                              {field.name}
                                            </Typography>
                                          }
                                          secondary={`Type: ${field.field_type} | Required: ${field.required ? 'Yes' : 'No'}`}
                                        />
                                        <ListItemSecondaryAction>
                                          <IconButton edge="end" onClick={() => handleEditField(field)}>
                                            <EditIcon />
                                          </IconButton>
                                          <IconButton edge="end" onClick={() => openConfirmDeleteFieldDialog(field.id)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    )}
                                  </Draggable>
                                ))
                              ) : (
                                <Typography>No fields yet.</Typography>
                              )}
                              {provided.placeholder}
                            </List>
                          )}
                        </Droppable>
                      </CardContent>

                      {/* Section edit and delete buttons moved to bottom right */}
                      <Box sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex', gap: 1 }}>
                        <IconButton onClick={() => openEditSectionDialog(section.id, section.name)}>
                          <EditIcon />
                        </IconButton>
                        <Button
                          onClick={() => openConfirmDeleteDialog(section.id)}
                          variant="contained"
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            '&:hover': { backgroundColor: 'darkred' },
                          }}
                        >
                          Delete Section
                        </Button>
                      </Box>
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Confirm Delete Section Dialog */}
      <Dialog open={confirmDeleteDialogOpen} onClose={() => setConfirmDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            This will delete the section and all fields associated with the section. Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteSection} variant="contained" color="error">
            Delete Section
          </Button>
          <Button onClick={() => setConfirmDeleteDialogOpen(false)} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Field Dialog */}
      <Dialog open={confirmDeleteFieldDialogOpen} onClose={() => setConfirmDeleteFieldDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            This will delete the field. Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteField} variant="contained" color="error">
            Delete Field
          </Button>
          <Button onClick={() => setConfirmDeleteFieldDialogOpen(false)} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Adding a New Section */}
      <Dialog open={sectionDialogOpen} onClose={() => setSectionDialogOpen(false)}>
        <DialogTitle>Add New Section</DialogTitle>
        <DialogContent>
          <TextField
            label="Section Name"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddSection} variant="contained">
            Add Section
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Editing Section Name */}
      <Dialog open={editSectionDialogOpen} onClose={() => setEditSectionDialogOpen(false)}>
        <DialogTitle>Edit Section</DialogTitle>
        <DialogContent>
          <TextField
            label="Section Name"
            value={editSectionName}
            onChange={(e) => setEditSectionName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSection} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Adding or Editing a Field */}
      <Dialog
        open={fieldDialogOpen}
        onClose={() => {
          setFieldDialogOpen(false);
          setEditField(null);
          setNewField({
            name: '',
            intake_question: '',
            api_name: '',
            field_type: 'text',
            required: false,
            dropdown_options: [],
          });
          setNewDropdownOption('');
          setEditIndex(null);
          setEditingOption('');
        }}
      >
        <DialogTitle>{editField ? 'Edit Field' : 'Add New Field'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Field Name"
            value={newField.name}
            onChange={(e) => {
              const updatedName = e.target.value;
              setNewField((prev) => ({
                ...prev,
                name: updatedName,
                api_name: generateApiName(updatedName), // Automatically generate api_name
              }));
            }}
            fullWidth
            sx={{ mt: 2 }}
          />

          {/* Display api_name as read-only */}
          <TextField
            label="API Name"
            value={newField.api_name}
            fullWidth
            sx={{ mt: 2 }}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            label="Intake Question"
            value={newField.intake_question}
            onChange={(e) => setNewField((prev) => ({ ...prev, intake_question: e.target.value }))}
            fullWidth
            sx={{ mt: 2 }}
          />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink={true}>Required</InputLabel>
            <Select
              value={newField.required ? 'true' : 'false'}
              onChange={(e) => setNewField((prev) => ({ ...prev, required: e.target.value === 'true' }))}
              label="Required"
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink={true}>Field Type</InputLabel>
            <Select
              value={newField.field_type}
              onChange={(e) => setNewField((prev) => ({ ...prev, field_type: e.target.value }))}
              label="Field Type"
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
              <MenuItem value="dropdown">Dropdown</MenuItem>
            </Select>
          </FormControl>

          {newField.field_type === 'dropdown' && (
            <Box sx={{ mt: 2 }}>
              {/* Input for Adding New Dropdown Option */}
              <Typography variant="subtitle1">Add New Dropdown Option</Typography>
              <TextField
                label="New Option"
                value={newDropdownOption}
                onChange={(e) => setNewDropdownOption(e.target.value)}
                fullWidth
              />
              <Button
                sx={{ mt: 2 }}
                onClick={handleAddDropdownOption}
                variant="contained"
                disabled={!newDropdownOption.trim()} // Disable button if input is empty
              >
                Add Option
              </Button>

              {/* Display Added Options */}
              {newField.dropdown_options.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Existing Options</Typography>
                  <List sx={{ mt: 1, border: '1px solid #ccc', borderRadius: '4px', padding: 1 }}>
                    {newField.dropdown_options.map((option, index) => (
                      <ListItem key={index} sx={{ borderBottom: '1px solid #eee' }}>
                        {editIndex === index ? (
                          <>
                            <TextField
                              value={editingOption}
                              onChange={(e) => setEditingOption(e.target.value)}
                              fullWidth
                            />
                            <IconButton onClick={saveDropdownOption} edge="end">
                              <CheckIcon />
                            </IconButton>
                            <IconButton onClick={cancelEditDropdownOption} edge="end">
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <ListItemText primary={option} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => startEditDropdownOption(index)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton edge="end" onClick={() => handleRemoveDropdownOption(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={editField ? handleSaveFieldChanges : handleAddField} // Use appropriate handler
            variant="contained"
          >
            {editField ? 'Save Changes' : 'Add Field'} {/* Dynamically update button text */}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IntakeManagement;
