// src/redux/store.js

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import tasksReducer from './tasksSlice';
import scheduledReachOutsReducer from './scheduledReachOutsSlice';
import notificationReducer from './notificationSlice';
import snackbarReducer from './snackbarSlice'; 


const appReducer = combineReducers({
  auth: authReducer,
  tasks: tasksReducer,
  scheduledReachOuts: scheduledReachOutsReducer,
  notifications: notificationReducer,
  snackbar: snackbarReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logOut') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Only 'auth' slice will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
