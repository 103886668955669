// src/pages/NotificationsPage.js

import React, { useEffect } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllNotifications, markNotificationAsRead } from '../redux/notificationSlice';
import theme from '../theme/theme'; // Import your custom theme

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.allItems || []);

  useEffect(() => {
    dispatch(fetchAllNotifications());
  }, [dispatch]);

  const handleNotificationClick = (notification) => {
    dispatch(markNotificationAsRead(notification.id));
    // Optionally navigate or perform action based on notification
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{
            color: theme.palette.text.primary,
            fontWeight: 700,
          }}>
        All Notifications
      </Typography>
      <Divider />
      <List>
        {notifications.length === 0 ? (
          <ListItemButton>
            <ListItemText primary="No notifications" />
          </ListItemButton>
        ) : (
          notifications.map((notification) => (
            <ListItemButton key={notification.id} onClick={() => handleNotificationClick(notification)}>
              <ListItemText
                sx={{ color: theme.palette.text.primary }}
                primary={notification.message}
                secondary={new Date(notification.created_at).toLocaleString()}
              />
            </ListItemButton>
          ))
        )}
      </List>
    </Box>
  );
};

export default NotificationsPage;
