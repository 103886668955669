// src/components/CreateTaskDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, MenuItem, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskThunk } from '../redux/tasksSlice';
import { api } from '../services/authService';
import moment from 'moment-timezone'; // Import moment-timezone
import { DateTimePicker } from '@mui/x-date-pickers'; // Updated import

const CreateTaskDialog = ({ open, handleClose, accountId, campaignId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC'; // Get user's timezone

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    due_date: null,
    priority: 'medium',
    status: 'pending',
    assigned_to: 'Unassigned',
  });

  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch users who have access to the campaign
    const fetchUsers = async () => {
      try {
        const response = await api.get(
          `/crm/accounts/${accountId}/campaigns/users/?campaign_id=${campaignId}`
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (accountId && campaignId) {
      fetchUsers();
    }
  }, [accountId, campaignId]);

  const handleSubmit = () => {
    // Convert due_date from user's timezone to UTC using moment-timezone
    let dueDateUtc = null;
    if (formData.due_date) {
      dueDateUtc = moment
        .tz(formData.due_date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    const taskData = {
      ...formData,
      due_date: dueDateUtc,
      campaign: campaignId,
      assigned_to:
        formData.assigned_to === 'Unassigned' ? null : formData.assigned_to,
    };
    dispatch(createTaskThunk(taskData));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Create Task</DialogTitle>
      <DialogContent>
        {/* Title */}
        <TextField
          label="Title"
          fullWidth
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          margin="normal"
        />
        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          margin="normal"
        />
        {/* Due Date */}
        <DateTimePicker
          label="Due Date"
          value={formData.due_date}
          onChange={(newValue) => setFormData({ ...formData, due_date: newValue })}
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="normal" />
          )}
        />
        {/* Priority */}
        <TextField
          label="Priority"
          select
          fullWidth
          value={formData.priority}
          onChange={(e) =>
            setFormData({ ...formData, priority: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="high">High</MenuItem>
        </TextField>
        {/* Status */}
        <TextField
          label="Status"
          select
          fullWidth
          value={formData.status}
          onChange={(e) =>
            setFormData({ ...formData, status: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
        </TextField>
        {/* Assigned To */}
        <TextField
          label="Assigned To"
          select
          fullWidth
          value={formData.assigned_to}
          onChange={(e) =>
            setFormData({ ...formData, assigned_to: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="Unassigned">Unassigned</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </MenuItem>
          ))}
        </TextField>
        {/* Buttons */}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Create Task
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTaskDialog;
