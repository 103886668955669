// src/pages/Campaigns.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  FormHelperText
} from '@mui/material';
import { Link } from 'react-router-dom';
import { api } from '../services/authService';

const Campaigns = () => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);  // List of accounts
  const [selectedAccountId, setSelectedAccountId] = useState('');  // Selected account ID
  const [selectedAccount, setSelectedAccount] = useState(null);  // Selected account object
  const [campaigns, setCampaigns] = useState([]);  // List of campaigns under selected account
  const [openDialog, setOpenDialog] = useState(false);  // Dialog for new campaign
  const [newCampaignName, setNewCampaignName] = useState('');  // New campaign name
  const [newCampaignDescription, setNewCampaignDescription] = useState('');  // New campaign description
  const [campaignModels, setCampaignModels] = useState([]);  // List of campaign models
  const [selectedCampaignModel, setSelectedCampaignModel] = useState('');  // Selected campaign model ID
  const [errors, setErrors] = useState({});  // Validation errors
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });  // Snackbar for notifications

  useEffect(() => {
    fetchAccounts();  // Fetch accounts when the component mounts
    fetchCampaignModels();  // Fetch campaign models when the component mounts
  }, []);

  useEffect(() => {
    if (selectedAccountId) {
      fetchCampaigns(selectedAccountId);  // Fetch campaigns whenever selectedAccountId changes
    }
  }, [selectedAccountId]);

  // Fetch accounts from the API
  const fetchAccounts = async () => {
    try {
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
      // Optionally select the first account by default
      if (response.data.length > 0) {
        setSelectedAccountId(response.data[0].id);
        setSelectedAccount(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      showSnackbar('Error fetching accounts', 'error');
    }
  };

  // Fetch campaigns based on the selected account
  const fetchCampaigns = async (accountId) => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      showSnackbar('Error fetching campaigns', 'error');
    }
  };

  // Fetch campaign models from the API
  const fetchCampaignModels = async () => {
    try {
      const response = await api.get('/crm/campaign_models/');
      setCampaignModels(response.data);
    } catch (error) {
      console.error('Error fetching campaign models:', error);
      showSnackbar('Error fetching campaign models', 'error');
    }
  };

  // Handle creating a new campaign
  const handleCreateCampaign = async () => {
    // Reset previous errors
    setErrors({});
    let validationErrors = {};

    // Validate required fields
    if (!newCampaignName.trim()) {
      validationErrors.name = 'Campaign Name is required';
    }
    if (!selectedCampaignModel) {
      validationErrors.campaign_model = 'Campaign Model is required';
    }

    // If there are validation errors, set them and show a snackbar
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      showSnackbar('Please fix the errors in the form', 'error');
      return;
    }

    // Prepare the payload
    const newCampaignData = {
      name: newCampaignName,
      description: newCampaignDescription,
      account_id: selectedAccountId,  // Associate the campaign with the selected account
      campaign_model: selectedCampaignModel,  // Selected campaign model
      campaign_model_id: selectedCampaignModel,
    };
    
    try {
      await api.post(`/crm/campaigns/`, newCampaignData);
      setOpenDialog(false);  // Close the dialog after successful creation
      setNewCampaignName('');  // Clear input after creation
      setNewCampaignDescription(''); // Clear description after creation
      setSelectedCampaignModel(''); // Reset campaign model selection
      fetchCampaigns(selectedAccountId);  // Refresh campaigns after creation
      showSnackbar('Campaign created successfully', 'success');
    } catch (error) {
      console.error('Error creating campaign:', error);
      // Handle specific error responses from the backend
      if (error.response && error.response.data) {
        const backendErrors = error.response.data;
        let formattedErrors = {};
        for (let key in backendErrors) {
          formattedErrors[key] = backendErrors[key][0];
        }
        setErrors(formattedErrors);
      }
      showSnackbar('Error creating campaign', 'error');
    }
  };

  const handleCampaignSelect = (campaignId) => {
    navigate(`/crm/campaign/${campaignId}`, {
      state: { accountId: selectedAccountId }, // Pass the selectedAccount using the state
    });
  };

  // Handle Snackbar close
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Function to show snackbar
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" color="textPrimary">My Campaigns</Typography>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          Create New Campaign
        </Button>
      </Box>

      {/* Account Selection Dropdown */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Account</InputLabel>
        <Select
          value={selectedAccountId}
          onChange={(e) => {
            setSelectedAccountId(e.target.value);
            const account = accounts.find(acc => acc.id === e.target.value);
            setSelectedAccount(account);
          }}
          label="Select Account"
        >
          {accounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* List of Campaigns */}
      <List>
        {campaigns.map((campaign) => (
          <Paper key={campaign.id} elevation={1} sx={{ mb: 2 }}>
            <ListItem 
              button 
              onClick={() => handleCampaignSelect(campaign.id)} // Handle navigation on click
            >
              <ListItemText 
                primary={campaign.name} 
                secondary={campaign.description ? campaign.description : 'No Description'} 
              />
            </ListItem>
          </Paper>
        ))}
      </List>

      {/* Dialog for Creating a New Campaign */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Create New Campaign</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="dense"
              label="Campaign Name"
              fullWidth
              required
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}  // Controlled input for campaign name
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              margin="dense"
              label="Campaign Description"
              fullWidth
              multiline
              rows={4}
              value={newCampaignDescription}
              onChange={(e) => setNewCampaignDescription(e.target.value)}  // Controlled input for description
            />
            {/* Campaign Model Selection */}
            <FormControl 
              fullWidth 
              sx={{ mt: 2 }} 
              error={!!errors.campaign_model}
              required
            >
              <InputLabel id="campaign-model-label">Campaign Model</InputLabel>
              <Select
                labelId="campaign-model-label"
                value={selectedCampaignModel}
                onChange={(e) => setSelectedCampaignModel(e.target.value)}
                label="Campaign Model *"
              >
                {campaignModels.map((model) => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.campaign_model && (
                <FormHelperText>{errors.campaign_model}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary" variant="contained">Cancel</Button>
          <Button onClick={handleCreateCampaign} color="primary" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Campaigns;
