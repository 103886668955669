// src/components/LeadsTable.js

import React, { useState, useRef } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, IconButton, TableSortLabel, CircularProgress, Box, TextField, Select, MenuItem,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import './LeadsTable.css'; // Import the CSS file for styling

const LeadsTable = ({
  leads,
  columns,
  loading,
  orderBy,
  order,
  handleRequestSort,
  handleCellClick,
  handleCellChange,
  editingCell,
  navigate,
  handleActionsMenuOpen,
  selectedLeads,
  handleLeadSelect,
  phoneNumberRegex,
  emailRegex,
  leadStatuses,
  campaignMembers,
  funnelSteps,
  dynamicFields, // Added dynamicFields prop
}) => {
  const theme = useTheme();

  // State to keep track of column widths
  const [columnWidths, setColumnWidths] = useState(
    columns.reduce((acc, column) => {
      acc[column.id] = column.minWidth || 100;
      return acc;
    }, {})
  );

  // Refs for tracking resizing
  const resizingColumnRef = useRef(null);
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);

  const handleMouseDown = (event, columnId) => {
    resizingColumnRef.current = columnId;
    startXRef.current = event.clientX;
    startWidthRef.current = columnWidths[columnId];

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    if (resizingColumnRef.current) {
      const deltaX = event.clientX - startXRef.current;
      const newWidth = startWidthRef.current + deltaX;

      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [resizingColumnRef.current]: Math.max(newWidth, 50), // Minimum width of 50px
      }));
    }
  };

  const handleMouseUp = () => {
    resizingColumnRef.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const renderCell = (lead, column) => {
    const isEditing = editingCell && editingCell.leadId === lead.id && editingCell.columnId === column.id;

    // Handling dynamic fields
    if (column.id.startsWith('dynamic_')) {
      const apiName = column.id.replace('dynamic_', '');
      const fieldDefinition = dynamicFields.find((field) => field.api_name === apiName);

      if (!fieldDefinition) {
        console.error(`Field definition not found for api_name: ${apiName}`);
        return (
          <div style={{ color: 'red' }}>
            Field definition not found
          </div>
        );
      }

      const fieldValue = (lead.field_values || []).find(
        (value) => value.field_api_name === apiName
      );
      const value = fieldValue ? fieldValue.value : '';

      const handleValueChange = (e) => {
        const newValue = e.target.value;
        handleCellChange(lead.id, { [column.id]: newValue }, fieldDefinition.id);
      };

      // Handling editing
      if (isEditing || lead.id === 'new') {
        if (fieldDefinition.field_type === 'dropdown' && fieldDefinition.dropdown_options) {
          return (
            <Select
              value={value || ''}
              onChange={handleValueChange}
              fullWidth
            >
              {fieldDefinition.dropdown_options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          );
        } else if (fieldDefinition.field_type === 'number') {
          return (
            <TextField
              value={value || ''}
              onChange={handleValueChange}
              type="number"
              fullWidth
            />
          );
        } else if (fieldDefinition.field_type === 'date') {
          return (
            <TextField
              value={value || ''}
              onChange={handleValueChange}
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          );
        } else if (fieldDefinition.field_type === 'boolean') {
          return (
            <Select
              value={value || ''}
              onChange={handleValueChange}
              fullWidth
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          );
        } else {
          // For text and other types
          return (
            <TextField
              value={value || ''}
              onChange={handleValueChange}
              fullWidth
            />
          );
        }
      }

      // Displaying value in read mode
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {value || '—'}
        </div>
      );
    }

    // Handling the "status" field as a dropdown
    if (column.id === 'status') {
      const statusValue = lead.status?.id || lead.status || '';
      
      if (isEditing || lead.id === 'new') {
        return (
          <Select
            value={statusValue}
            onChange={(e) => handleCellChange(lead.id, { status: e.target.value })}
            fullWidth
          >
            {leadStatuses.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        );
      }

      const statusName = lead.status_name || 
        leadStatuses.find(status => 
          status.id === (lead.status?.id || lead.status)
        )?.name || 
        'No Status';

      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {statusName}
        </div>
      );
    }    

    // Handling the "assigned_to" field
    if (column.id === 'assigned_to') {
      const assignedToId = lead.assigned_to?.id || lead.assigned_to;
    
      return isEditing || lead.id === 'new' ? (
        <Select
          value={assignedToId || ''}
          onChange={(e) => handleCellChange(lead.id, { assigned_to: e.target.value })}
          fullWidth
        >
          {campaignMembers.map((member) => (
            <MenuItem key={member.user.id} value={member.user.id}>
              {`${member.user.first_name} ${member.user.last_name}`}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {assignedToId
            ? `${campaignMembers.find((m) => m.user.id === assignedToId)?.user.first_name} ${campaignMembers.find((m) => m.user.id === assignedToId)?.user.last_name}`
            : 'Unassigned'}
        </div>
      );
    }    

    // Handling the "lead_details" column
    if (column.id === 'lead_details') {
      return (
        <IconButton onClick={() => navigate(`/crm/leads/${lead.id}/details`)}>
          <SearchIcon />
        </IconButton>
      );
    }

    // Handling the "actions" column
    if (column.id === 'actions') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton onClick={(event) => handleActionsMenuOpen(event, lead)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      );
    }

    // Custom onChange handlers for date_of_birth and age
    const handleValueChange = (e) => {
      const newValue = e.target.value || null; // Set to null if empty

      if (column.id === 'date_of_birth') {
        if (newValue) {
          // Calculate age from date_of_birth
          const birthDate = new Date(newValue);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          // Update both date_of_birth and age
          handleCellChange(lead.id, { date_of_birth: newValue, age: age });
        } else {
          // Clear both date_of_birth and age
          handleCellChange(lead.id, { date_of_birth: null, age: null });
        }
      } else if (column.id === 'age') {
        // Clear date_of_birth when age is manually entered
        handleCellChange(lead.id, { age: newValue, date_of_birth: null });
      } else {
        handleCellChange(lead.id, { [column.id]: newValue });
      }
    };

    // Validation for phone number format
    if (column.id === 'phone_number') {
      const isValidPhoneNumber = phoneNumberRegex.test(lead.phone_number || '');

      return isEditing || lead.id === 'new' ? (
        <TextField
          value={lead.phone_number || ''}
          onChange={handleValueChange}
          error={!isValidPhoneNumber && lead.phone_number !== ''}
          helperText={!isValidPhoneNumber && lead.phone_number !== '' ? 'Format: XXX-XXX-XXXX' : ''}
          placeholder="XXX-XXX-XXXX"
          fullWidth
        />
      ) : (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {lead.phone_number || '—'}
        </div>
      );
    }

    // Validation for email format
    if (column.id === 'email') {
      const isValidEmail = emailRegex.test(lead.email || '');

      return isEditing || lead.id === 'new' ? (
        <TextField
          value={lead.email || ''}
          onChange={handleValueChange}
          error={!isValidEmail && lead.email !== ''}
          helperText={!isValidEmail && lead.email !== '' ? 'Invalid email address' : ''}
          placeholder="example@domain.com"
          fullWidth
        />
      ) : (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {lead.email || '—'}
        </div>
      );
    }

    // Handling editing of other fields
    if (isEditing || lead.id === 'new') {
      if (column.id === 'current_step') {
        // Get the current step value, handling both object and primitive formats
        const currentStepValue = lead.current_step?.id || lead.current_step || '';
        return (
          <Select
            value={currentStepValue}
            onChange={(e) => handleCellChange(lead.id, { current_step: e.target.value })}
            fullWidth
          >
            {funnelSteps.map((step) => (
              <MenuItem key={step.id} value={step.id}>
                {step.name}
              </MenuItem>
            ))}
          </Select>
        );
      }
      else if (column.id === 'date_of_birth' || column.id === 'age') {
        // Handled by custom handleValueChange
        return (
          <TextField
            value={lead[column.id] || ''}
            onChange={handleValueChange}
            type={column.id === 'date_of_birth' ? 'date' : 'number'}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      } else {
        return (
          <TextField
            value={lead[column.id] || ''}
            onChange={handleValueChange}
            type={column.type || 'text'}
            fullWidth
            autoFocus
          />
        );
      }
    }

    // Displaying the current step
    if (column.id === 'current_step') {
      const stepName = lead.current_step_name || 
        funnelSteps.find(step => 
          step.id === (lead.current_step?.id || lead.current_step)
        )?.name || 
        'N/A';
    
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {stepName}
        </div>
      );
    }

    // Default rendering
    return (
      <div onClick={() => handleCellClick(lead.id, column.id)}>
        {lead[column.id] || '—'}
      </div>
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: theme.palette.background.paper,
        maxHeight: '60vh',
        overflow: 'auto',
      }}
    >
      <Table stickyHeader aria-label="leads table">
        <TableHead>
          <TableRow>
            {/* Checkbox Column Header */}
            <TableCell
              className="fixed-column-checkbox"
              sx={{
                position: 'sticky',
                top: 0,
                left: 0,
                zIndex: 4,
                backgroundColor: '#1F1F1F',
                color: theme.palette.text.primary,
                fontWeight: 'bold',
                minWidth: 50,
              }}
            >
              <Checkbox
                indeterminate={selectedLeads.length > 0 && selectedLeads.length < leads.length}
                checked={selectedLeads.length === leads.length && leads.length > 0}
                onChange={(e) =>
                  handleLeadSelect('all', e.target.checked)
                }
                inputProps={{ 'aria-label': 'select all leads' }}
              />
            </TableCell>

            {columns.map((column) => {
              const isFixedColumn =
                column.id === 'lead_details' || column.id === 'actions';

              let positionProps = {};
              if (column.id === 'lead_details') {
                positionProps = { left: '50px' };
              } else if (column.id === 'actions') {
                positionProps = { right: 0 };
              }

              return (
                <TableCell
                  key={column.id}
                  className={`${isFixedColumn ? 'fixed-column' : ''}`}
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: isFixedColumn ? 4 : 2,
                    backgroundColor: '#1F1F1F',
                    color: theme.palette.text.primary,
                    fontWeight: 'bold',
                    ...(isFixedColumn && positionProps),
                    minWidth: columnWidths[column.id],
                    width: columnWidths[column.id],
                    whiteSpace: 'nowrap',
                    userSelect: 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                        sx={{ color: theme.palette.text.primary }}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                    {/* Resizer */}
                    {!isFixedColumn && (
                      <div
                        onMouseDown={(e) => handleMouseDown(e, column.id)}
                        className="column-resizer"
                      />
                    )}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : leads.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={columns.length + 1}
                align="center"
                sx={{ color: theme.palette.text.secondary }}
              >
                No leads found.
              </TableCell>
            </TableRow>
          ) : (
            leads.map((lead) => (
              <TableRow hover tabIndex={-1} key={lead.id}>
                {/* Checkbox Cell */}
                <TableCell
                  className="fixed-column-checkbox"
                  sx={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 3,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    minWidth: 50,
                    width: 50,
                  }}
                >
                  <Checkbox
                    checked={selectedLeads.includes(lead.id)}
                    onChange={(e) => handleLeadSelect(lead.id)}
                    inputProps={{ 'aria-label': `select lead ${lead.id}` }}
                  />
                </TableCell>

                {columns.map((column) => {
                  const isFixedColumn =
                    column.id === 'lead_details' || column.id === 'actions';

                  let positionProps = {};
                  if (column.id === 'lead_details') {
                    positionProps = { left: '50px' };
                  } else if (column.id === 'actions') {
                    positionProps = { right: 0 };
                  }

                  return (
                    <TableCell
                      key={column.id}
                      className={`${isFixedColumn ? `fixed-column ${column.id}` : ''}`}
                      sx={{
                        position: isFixedColumn ? 'sticky' : 'relative',
                        zIndex: isFixedColumn ? 3 : 1,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                        ...(isFixedColumn && positionProps),
                        minWidth: columnWidths[column.id],
                        width: columnWidths[column.id],
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {renderCell(lead, column)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

LeadsTable.propTypes = {
  leads: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleCellClick: PropTypes.func.isRequired,
  handleCellChange: PropTypes.func.isRequired,
  editingCell: PropTypes.object,
  navigate: PropTypes.func.isRequired,
  handleActionsMenuOpen: PropTypes.func.isRequired,
  selectedLeads: PropTypes.array.isRequired,
  handleLeadSelect: PropTypes.func.isRequired,
  phoneNumberRegex: PropTypes.object.isRequired,
  emailRegex: PropTypes.object.isRequired,
  leadStatuses: PropTypes.array.isRequired,
  campaignMembers: PropTypes.array.isRequired,
  funnelSteps: PropTypes.array.isRequired,
  dynamicFields: PropTypes.array.isRequired, // Added prop type for dynamicFields
};

export default LeadsTable;
