// src/services/taskService.js

import { api } from './authService';

export const fetchTasks = (campaignId) => {
  return api.get(`/crm/tasks/?campaign_id=${campaignId}`);
};

export const createTask = (taskData) => {
  return api.post('/crm/tasks/', taskData);
};

export const updateTask = (taskId, taskData) => {
  return api.put(`/crm/tasks/${taskId}/`, taskData);
};

export const deleteTask = (taskId) => {
  return api.delete(`/crm/tasks/${taskId}/`);
};
