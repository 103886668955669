// src/components/GoogleIntegration.js

import React, { useState, useEffect } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../services/authService';
import { showSnackbar } from '../redux/snackbarSlice';

const GoogleIntegration = () => {
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const dispatch = useDispatch();

  // Retrieve the redirect URI from environment variables
  const REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

  useEffect(() => {
    // Check if the user has already connected Google
    const checkGoogleConnection = async () => {
      try {
        const response = await api.get('/integrations/google/status/');
        setIsGoogleConnected(response.data.is_connected);
      } catch (error) {
        // Handle error, optionally show a snackbar for failed status check
        dispatch(showSnackbar({ message: 'Failed to check Google connection status.', severity: 'error' }));
      }
    };
    checkGoogleConnection();
  }, [dispatch]);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        await api.post('/integrations/google/', {
          code: codeResponse.code,
          redirect_uri: REDIRECT_URI, // Pass the redirect URI here
        });
        setIsGoogleConnected(true);
        dispatch(showSnackbar({ message: 'Google account connected successfully!', severity: 'success' }));
      } catch (error) {
        dispatch(showSnackbar({ message: 'Failed to connect Google account.', severity: 'error' }));
      }
    },
    onError: () => {
      dispatch(showSnackbar({ message: 'Google sign-in was unsuccessful. Please try again later.', severity: 'error' }));
    },
    flow: 'auth-code',
    scope: 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar',
  });  

  const handleGoogleDisconnect = async () => {
    try {
      await api.post('/integrations/google/disconnect/');
      setIsGoogleConnected(false);
      dispatch(showSnackbar({ message: 'Google account disconnected successfully!', severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: 'Failed to disconnect Google account.', severity: 'error' }));
    }
  };

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography variant="subtitle1">Google Account</Typography>
      {!isGoogleConnected ? (
        <Button variant="contained" onClick={() => login()}>
          Connect Google Account
        </Button>
      ) : (
        <Button variant="contained" color="secondary" onClick={handleGoogleDisconnect}>
          Disconnect Google Account
        </Button>
      )}
    </Paper>
  );
};

export default GoogleIntegration;
