// src/theme/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Dark mode for the entire app
    primary: {
      main: '#4876EE', // Pink tone from Novaura logo
    },
    secondary: {
      main: '#FF5A79', // Blue tone from Novaura logo 
    },
    background: {
      default: '#2E2E2E', // Dark gray background for the whole app
      paper: '#3C3C3C',   // Slightly lighter gray for paper components (cards, modals)
    },
    text: {
      primary: '#FFFFFF', // White text color
      secondary: '#B4C0D3', // Light gray text for secondary elements
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontWeight: 700,
      color: '#FFFFFF', // Header text color in white
    },
    body2: {
      color: '#FFFFFF', // Ensure all body text is white in dark mode
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Rounded buttons for a modern look
        },
      },
    },
  },
});

export default theme;
