// src/services/authService.js
import axios from 'axios';
import { setCredentials, logOut } from '../redux/authSlice';
import store from '../redux/store';


// Create an Axios instance with credentials enabled for authenticated requests
const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,  // Ensure cookies (like CSRF tokens) are sent with requests
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Create an instance without interceptors for public endpoints
const apiWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,  // This is necessary for handling CSRF cookies in public endpoints
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export const getToken = () => {
  return localStorage.getItem('token');
};

// Fetch the CSRF token from localStorage
export const getCsrfToken = () => localStorage.getItem('csrfToken');

export const loginUser = async (credentials) => {
  try {
    const response = await apiWithoutAuth.post('/accounts/token/', credentials);
    const { access, refresh } = response.data;

    // Store tokens in localStorage
    localStorage.setItem('token', access);
    localStorage.setItem('refreshToken', refresh);

    // Dispatch to Redux store
    store.dispatch(setCredentials({ token: access, refreshToken: refresh }));

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async (refreshToken) => {
  try {
    await api.post('/accounts/logout/', { refresh_token: refreshToken });
  } catch (error) {
    console.error('Error logging out:', error);
  }
};

// Add a request interceptor for api instance
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const csrfToken = getCsrfToken();

    // Attach JWT token to the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;  // Attach the JWT token to all requests
    }

    // Attach CSRF token to the X-CSRFToken header for non-GET requests
    if (csrfToken && ['post', 'put', 'delete'].includes(config.method)) {
      config.headers['X-CSRFToken'] = csrfToken;
    }

    return config;
  },
  (error) => {
    console.error('Interceptor - Request error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token refresh logic
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 error by refreshing the token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await apiWithoutAuth.post('/accounts/token/refresh/', { refresh: refreshToken });

        const { access, refresh } = response.data;

        // Update tokens in localStorage and Redux store
        localStorage.setItem('token', access);
        if (refresh) {
          localStorage.setItem('refreshToken', refresh);
          store.dispatch(setCredentials({ token: access, refreshToken: refresh }));
        } else {
          store.dispatch(setCredentials({ token: access }));
        }

        // Retry the original request with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${access}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refreshing token fails, log the user out
        store.dispatch(logOut());
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export { api, apiWithoutAuth };