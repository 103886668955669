// src/components/CategorySidebar.js

import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { categories } from '../data/categories'; // We'll define this next
import { Category as CategoryIcon } from '@mui/icons-material';

const CategorySidebar = ({ selectedCategory, onSelectCategory }) => {
  const theme = useTheme();

  return (
    <List component="nav" sx={{ width: '100%' }}>
      {categories.map((category) => (
        <ListItem
          button
          key={category}
          selected={selectedCategory === category}
          onClick={() => onSelectCategory(category)}
          sx={{
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          }}
        >
          <ListItemIcon>
            <CategoryIcon sx={{ color: theme.palette.text.primary }} />
          </ListItemIcon>
          <ListItemText primary={category} sx={{ color: theme.palette.text.primary }} />
        </ListItem>
      ))}
    </List>
  );
};

export default CategorySidebar;
