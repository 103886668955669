// src/components/ErrorModal.js

import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Button, Box, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import PropTypes from 'prop-types';

const ErrorModal = ({ open, onClose, failedRowsData, downloadFailedRowsCSV }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="import-errors-dialog-title"
    >
      <DialogTitle id="import-errors-dialog-title">Import Errors</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Some leads failed to import due to the following errors:
        </DialogContentText>
        <Box sx={{ mt: 2, maxHeight: '60vh', overflow: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><strong>Row Number</strong></TableCell>
                <TableCell><strong>Errors</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {failedRowsData.map((failedRow, index) => (
                <TableRow key={index}>
                  <TableCell>{failedRow.row}</TableCell>
                  <TableCell>
                    <ul style={{ margin: 0, paddingLeft: '20px' }}>
                      {failedRow.errors.map((error, idx) => (
                        <li key={idx}>{error}</li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {failedRowsData.length > 0 && (
          <Button onClick={downloadFailedRowsCSV} color="secondary">
            Download Failed Rows
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  failedRowsData: PropTypes.array.isRequired,
  downloadFailedRowsCSV: PropTypes.func.isRequired,
};

export default ErrorModal;
