// src/pages/SetPassword.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Alert,
} from '@mui/material';
import { apiWithoutAuth } from '../services/authService';

const SetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [status, setStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('');
    setErrorMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const uid = searchParams.get('uid');
    const token = searchParams.get('token');

    if (!uid || !token) {
      setErrorMessage('Invalid or missing activation data.');
      return;
    }

    try {
      const response = await apiWithoutAuth.post('/accounts/set-password/', {
        uid,
        token,
        password,
      });
      if (response.status === 200) {
        setStatus('success');
      } else {
        setErrorMessage('Failed to set password.');
      }
    } catch (error) {
      console.error('Set password error:', error);
      setErrorMessage('Failed to set password.');
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {status === 'success' ? (
          <>
            <Typography component="h1" variant="h5">
              Password Set Successfully!
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={handleLoginRedirect}
            >
              Go to Login
            </Button>
          </>
        ) : (
          <>
            <Typography component="h1" variant="h5">
              Set Your Password
            </Typography>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Set Password
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default SetPassword;
