// src/components/BulkActionsMenu.js

import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';

const BulkActionsMenu = ({
  anchorEl,
  open,
  onClose,
  handleBulkDelete,
  handleAssignFunnelClick,
  bulkActionLoading,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem onClick={handleBulkDelete} disabled={bulkActionLoading}>
        <ListItemIcon>
          {bulkActionLoading ? <CircularProgress size={20} /> : <DeleteIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleAssignFunnelClick} disabled={bulkActionLoading}>
        <ListItemIcon>
          {bulkActionLoading ? <CircularProgress size={20} /> : <SaveIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText>Assign to Funnel</ListItemText>
      </MenuItem>
    </Menu>
  );
};

BulkActionsMenu.propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleBulkDelete: PropTypes.func.isRequired,
  handleAssignFunnelClick: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool.isRequired,
};

export default BulkActionsMenu;
