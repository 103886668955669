// src/components/EditScheduledReachOutDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateScheduledReachOutThunk } from '../redux/scheduledReachOutsSlice';
import { api } from '../services/authService';
import moment from 'moment-timezone'; // Import moment-timezone
import { DateTimePicker } from '@mui/x-date-pickers'; // Import DateTimePicker

const EditScheduledReachOutDialog = ({ open, handleClose, reachOut, campaign }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC'; // Get user's timezone

  // State for form data and leads list
  const [formData, setFormData] = useState({
    scheduled_date: null,
    reason: '',
    description: '',
    notes: '',
    status: '',
    campaign: '',
    lead: '',
  });
  const [leads, setLeads] = useState([]);
  const [isLeadEditable, setIsLeadEditable] = useState(true); // Track editability of the lead field

  // Populate form data when reachOut prop changes
  useEffect(() => {
    if (reachOut) {
      setFormData({
        scheduled_date: reachOut.scheduled_date
          ? moment.tz(reachOut.scheduled_date, timezone)
          : null,
        reason: reachOut.reason,
        description: reachOut.description,
        notes: reachOut.notes,
        status: reachOut.status,
        campaign: reachOut.campaign,
        lead: reachOut.lead || '',
      });
      setIsLeadEditable(true); // Reset editability when new reachOut is loaded
    }
  }, [reachOut, timezone]);

  useEffect(() => {
    if (campaign && !reachOut?.lead) {
      // Fetch leads for the selected campaign
      const fetchLeads = async () => {
        try {
          let allLeads = [];
          let page = 1;
          let morePages = true;

          while (morePages) {
            const response = await api.get(
              `/crm/leads/?campaign_id=${campaign}&page=${page}`
            );
            allLeads = allLeads.concat(response.data.results || []);
            morePages = response.data.next !== null;
            page += 1;
          }

          setLeads(allLeads);
        } catch (error) {
          console.error('Error fetching leads:', error);
          setLeads([]);
        }
      };
      fetchLeads();
    }
  }, [campaign, reachOut?.lead]);

  const handleSubmit = () => {
    // Convert scheduled_date from user's timezone to UTC using moment-timezone
    let scheduledDateUtc = null;
    if (formData.scheduled_date) {
      scheduledDateUtc = moment
        .tz(formData.scheduled_date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    const updatedData = {
      ...formData,
      scheduled_date: scheduledDateUtc,
    };

    dispatch(
      updateScheduledReachOutThunk({
        scheduledReachOutId: reachOut.id,
        scheduledReachOutData: updatedData,
      })
    );
    setIsLeadEditable(false); // Set lead field to uneditable after saving
    handleClose();
  };

  const handleDialogClose = () => {
    // Reset the formData
    setFormData({
      scheduled_date: null,
      reason: '',
      description: '',
      notes: '',
      status: '',
      campaign: '',
      lead: '',
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Scheduled Callback</DialogTitle>
      <DialogContent>
        {/* Lead Field */}
        {isLeadEditable ? (
          // If lead is editable, display the select dropdown
          <TextField
            label="Select Lead (Optional)"
            select
            fullWidth
            value={formData.lead}
            onChange={(e) => setFormData({ ...formData, lead: e.target.value })}
            margin="normal"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {leads.map((lead) => (
              <MenuItem key={lead.id} value={lead.id}>
                {`${lead.first_name} ${lead.last_name}`}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          // Else, display uneditable TextField with lead name
          <TextField
            label="Lead"
            fullWidth
            value={reachOut.lead_name || 'N/A'}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        )}

        {/* Scheduled Date */}
        <DateTimePicker
          label="Scheduled Date"
          value={formData.scheduled_date}
          onChange={(newValue) => setFormData({ ...formData, scheduled_date: newValue })}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        />

        {/* Reason */}
        <TextField
          label="Reason"
          fullWidth
          multiline
          rows={3}
          value={formData.reason}
          onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
          margin="normal"
        />
        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={3}
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          margin="normal"
        />
        {/* Notes */}
        <TextField
          label="Notes"
          fullWidth
          multiline
          rows={3}
          value={formData.notes}
          onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          margin="normal"
        />
        {/* Status */}
        <TextField
          label="Status"
          select
          fullWidth
          value={formData.status}
          onChange={(e) => setFormData({ ...formData, status: e.target.value })}
          margin="normal"
        >
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="closed">Closed</MenuItem>
        </TextField>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDialogClose}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditScheduledReachOutDialog;