// src/data/categories.js

export const categories = [
    'All Integrations',
    'Marketing Automation',
    'Sales Enablement',
    'Communication',
    'Data Management',
    'Analytics & Reporting',
    'Lead Generation',
    'Customer Support',
    'Workflow Automation',
    'E-commerce',
    'Other',
  ];
  