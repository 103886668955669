// src/pages/Accounts.js

import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Box,
  Paper
} from '@mui/material';
import { Link } from 'react-router-dom';
import { api, getToken } from '../services/authService';
import { selectCurrentToken } from '../redux/authSlice';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const Accounts = () => {
  const theme = useTheme();
  const [accounts, setAccounts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');

  const tokenFromRedux = useSelector(selectCurrentToken);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      console.error('No token found, user might not be logged in');
      // Optionally redirect to login page here
    } else {
      fetchAccounts();
    }
  }, [tokenFromRedux]);

  const fetchAccounts = async () => {
    try {
      const token = getToken();
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  const handleCreateAccount = async () => {
    try {
      await api.post('/crm/accounts/', { name: newAccountName });
      setOpenDialog(false);
      setNewAccountName('');
      fetchAccounts();
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  return (
    <Box 
      sx={{ 
        padding: 2, 
        backgroundColor: theme.palette.background.default, 
        minHeight: '100vh' 
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2 
        }}
      >
        <Typography 
          variant="h4" 
          sx={{ color: theme.palette.text.primary, fontWeight: 700 }}
        >
          My Accounts
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setOpenDialog(true)}
          sx={{ borderRadius: theme.components.MuiButton.styleOverrides.root.borderRadius }}
        >
          Create New Account
        </Button>
      </Box>
      <List>
        {accounts.map((account) => (
          <Paper 
          key={account.id} 
          elevation={1} 
          sx={{ 
            mb: 2, 
            backgroundColor: theme.palette.background.paper 
          }}>
            <ListItem button component={Link} to={`/crm/account/${account.id}`} sx={{ 
                '&:hover': { backgroundColor: theme.palette.action.hover } 
              }}>
              <ListItemText 
                primary={account.name} 
                primaryTypographyProps={{ 
                  style: { color: theme.palette.text.primary, fontWeight: 500 } 
                }} 
              />
            </ListItem>
          </Paper>
        ))}
      </List>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Account</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Account Name"
            fullWidth
            value={newAccountName}
            onChange={(e) => setNewAccountName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateAccount} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Accounts;