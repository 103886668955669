// src/hooks/useNotificationPolling.js

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../redux/notificationSlice';
import { selectCurrentToken } from '../redux/authSlice';

const useNotificationPolling = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    let intervalId;

    if (token) {
      dispatch(fetchNotifications());

      intervalId = setInterval(() => {
        dispatch(fetchNotifications());
      }, 1800000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [dispatch, token]);
};

export default useNotificationPolling;
