// src/components/CreateScheduledReachOutDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createScheduledReachOutThunk } from '../redux/scheduledReachOutsSlice';
import { api } from '../services/authService';
import PropTypes from 'prop-types';
import moment from 'moment-timezone'; // Import moment-timezone
import { DateTimePicker } from '@mui/x-date-pickers'; // Updated import

const CreateScheduledReachOutDialog = ({
  open,
  handleClose,
  campaignId,
  accountId,
  leadId,
  leadName,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC';

  const [formData, setFormData] = useState({
    lead: leadId || '',
    campaign: campaignId,
    scheduled_date: null,
    reason: '',
    description: '',
    notes: '',
    status: 'open',
  });
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (campaignId && !leadId) {
      // Fetch leads for the selected campaign
      const fetchLeads = async () => {
        try {
          const response = await api.get(`/crm/leads/?campaign_id=${campaignId}`);
          if (response.data.results && Array.isArray(response.data.results)) {
            setLeads(response.data.results);
          } else {
            setLeads([]);
          }
        } catch (error) {
          console.error('Error fetching leads:', error);
          setLeads([]);
        }
      };
      fetchLeads();
    }
    // Set the campaign in formData
    setFormData((prevData) => ({ ...prevData, campaign: campaignId }));
  }, [campaignId, leadId]);

  const handleSubmit = async () => {
    // Convert scheduled_date from user's timezone to UTC using moment-timezone
    let scheduledDateUtc = null;
    if (formData.scheduled_date) {
      scheduledDateUtc = moment
        .tz(formData.scheduled_date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    // Prepare data to send
    const dataToSend = {
      ...formData,
      scheduled_date: scheduledDateUtc,
      campaign: campaignId,
      lead: formData.lead || null, // Set lead to null if empty
    };
    setLoading(true);
    try {
      await dispatch(createScheduledReachOutThunk(dataToSend)).unwrap();
      onSuccess('Scheduled reach-out created successfully!');
      handleDialogClose();
    } catch (error) {
      console.error('Error creating scheduled reach-out:', error);
      onError('Failed to create scheduled reach-out.');
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    // Reset the formData
    setFormData({
      lead: leadId || '',
      campaign: campaignId,
      scheduled_date: null,
      reason: '',
      description: '',
      notes: '',
      status: 'open',
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Scheduled Follow Up</DialogTitle>
      <DialogContent>
        {/* Conditionally render Lead information */}
        {leadId ? (
          <TextField
            label="Lead"
            fullWidth
            value={leadName}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <TextField
            label="Select Lead (Optional)"
            select
            fullWidth
            value={formData.lead}
            onChange={(e) => setFormData({ ...formData, lead: e.target.value })}
            margin="normal"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {leads.map((lead) => (
              <MenuItem key={lead.id} value={lead.id}>
                {`${lead.first_name} ${lead.last_name}`}
              </MenuItem>
            ))}
          </TextField>
        )}
        {/* Scheduled Date */}
        <DateTimePicker
          label="Scheduled Date"
          value={formData.scheduled_date}
          onChange={(newValue) =>
            setFormData({ ...formData, scheduled_date: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="normal" />
          )}
        />
        {/* Reason */}
        <TextField
          label="Reason"
          fullWidth
          value={formData.reason}
          onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
          margin="normal"
        />
        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={3}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          margin="normal"
        />
        {/* Notes */}
        <TextField
          label="Notes"
          fullWidth
          multiline
          rows={3}
          value={formData.notes}
          onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          margin="normal"
        />
        {/* Buttons */}
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDialogClose}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CreateScheduledReachOutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  leadId: PropTypes.string,
  leadName: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default CreateScheduledReachOutDialog;
