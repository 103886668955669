// src/pages/Funnels.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { api } from '../services/authService';

const Funnels = () => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [funnels, setFunnels] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newFunnel, setNewFunnel] = useState({ name: '', description: '', direction: 'inbound' });

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchCampaigns(selectedAccount);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedCampaign) {
      fetchFunnels(selectedCampaign);
    }
  }, [selectedCampaign]);

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
      if (response.data.length > 0) {
        setSelectedAccount(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchCampaigns = async (accountId) => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchFunnels = async (campaignId) => {
    try {
      const response = await api.get(`/crm/funnels/?campaign_id=${campaignId}`);
      setFunnels(response.data);
    } catch (error) {
      console.error('Error fetching funnels:', error);
    }
  };
  

  const handleFunnelSelect = (funnelId) => {
    navigate(`/crm/funnel/${funnelId}`, {
      state: { 
        accountId: selectedAccount, 
        campaignId: selectedCampaign 
      },
    });
  };

  const handleCreateFunnel = async () => {
    try {
      // Construct the payload for the funnel
      const newFunnelData = {
        name: newFunnel.name,
        description: newFunnel.description,
        direction: newFunnel.direction, // Ensure direction is passed correctly
        account_id: selectedAccount,    // Send the selected account ID
        campaign_id: selectedCampaign,  // Send the selected campaign ID
      };
  
      // Send the post request to the funnel creation API endpoint
      const response = await api.post(`/crm/funnels/`, newFunnelData);
  
      // If successful, close the dialog, clear form fields, and refresh the funnel list
      setOpenDialog(false);
      setNewFunnel({ name: '', description: '', direction: 'inbound' });  // Reset the form fields
      fetchFunnels(selectedCampaign);  // Refresh the funnels after creation
  
    } catch (error) {
      console.error('Error creating funnel:', error.response?.data || error.message);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" color="textPrimary">My Funnels</Typography>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          Create New Funnel
        </Button>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Account Selection Dropdown */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Account</InputLabel>
        <Select
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          label="Select Account"
        >
          {accounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Campaign Selection Dropdown */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Campaign</InputLabel>
        <Select
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          label="Select Campaign"
        >
          {campaigns.map((campaign) => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Divider sx={{ mb: 2 }} />

      {/* List of Funnels */}
      <List>
        {funnels.map((funnel) => (
          <Paper key={funnel.id} elevation={1} sx={{ mb: 2 }}>
            <ListItem button onClick={() => handleFunnelSelect(funnel.id)}>
              <ListItemText primary={funnel.name} secondary={funnel.description} />
            </ListItem>
          </Paper>
        ))}
      </List>

      {/* Dialog for Creating a New Funnel */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Funnel</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel>Select Account</InputLabel>
            <Select
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
              label="Select Account"
            >
              {accounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Campaign</InputLabel>
            <Select
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
              label="Select Campaign"
            >
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            label="Funnel Name"
            fullWidth
            value={newFunnel.name}
            onChange={(e) => setNewFunnel((prev) => ({ ...prev, name: e.target.value }))}
          />
          <TextField
            margin="dense"
            label="Funnel Description"
            fullWidth
            multiline
            rows={4}
            value={newFunnel.description}
            onChange={(e) => setNewFunnel((prev) => ({ ...prev, description: e.target.value }))}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Funnel Direction</InputLabel>
            <Select
              value={newFunnel.direction}
              onChange={(e) => setNewFunnel((prev) => ({ ...prev, direction: e.target.value }))}
              label="Funnel Direction"
            >
              <MenuItem value="inbound">Inbound</MenuItem>
              <MenuItem value="outbound">Outbound</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateFunnel} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Funnels;
