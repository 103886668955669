// src/components/LeadFieldsConfiguration.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import { api } from '../services/authService';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const LeadFieldsConfiguration = ({
  campaignId,
  campaignModelId,
  selectedLeadFields,
  setSelectedLeadFields,
  accountId,
  handleSaveLeadFieldConfig,
}) => {
  const theme = useTheme();

  const [availableLeadFields, setAvailableLeadFields] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // State variables for checkbox selections
  const [availableSelected, setAvailableSelected] = useState([]);
  const [selectedSelected, setSelectedSelected] = useState([]);

  // State for the add field dialog
  const [openAddFieldDialog, setOpenAddFieldDialog] = useState(false);
  const [newFieldData, setNewFieldData] = useState({
    name: '',
    field_type: '',
    required: false,
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Fetch available lead fields based on campaign model
  useEffect(() => {
    if (campaignModelId) {
      fetchAvailableLeadFields();
    }
  }, [campaignModelId]);

  const fetchAvailableLeadFields = async () => {
    try {
      const response = await api.get(`/crm/campaign_models/${campaignModelId}/lead_fields/`, {
        params: {
          exclude_intake_fields: true,
        },
      });
      setAvailableLeadFields(response.data);
    } catch (error) {
      console.error('Error fetching lead fields:', error);
      showSnackbar('Error fetching lead fields', 'error');
    }
  };

  // Handle checkbox toggle in the available list
  const handleToggleAvailable = (apiName) => () => {
    const currentIndex = availableSelected.indexOf(apiName);
    const newSelected = [...availableSelected];

    if (currentIndex === -1) {
      newSelected.push(apiName);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setAvailableSelected(newSelected);
  };

  // Handle checkbox toggle in the selected list
  const handleToggleSelected = (apiName) => () => {
    const currentIndex = selectedSelected.indexOf(apiName);
    const newSelected = [...selectedSelected];

    if (currentIndex === -1) {
      newSelected.push(apiName);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedSelected(newSelected);
  };

  // Add selected fields to selectedLeadFields
  const handleAddFields = () => {
    const newFields = availableSelected.filter(
      (apiName) => !selectedLeadFields.includes(apiName)
    );
    if (newFields.length === 0) {
      showSnackbar('No fields selected to add.', 'info');
      return;
    }

    setSelectedLeadFields([...selectedLeadFields, ...newFields]);
    setAvailableSelected([]);
  };

  // Remove selected fields from selectedLeadFields
  const handleRemoveFields = () => {
    const fieldsToRemove = selectedSelected;
    if (fieldsToRemove.length === 0) {
      showSnackbar('No fields selected to remove.', 'info');
      return;
    }

    const updatedFields = selectedLeadFields.filter(
      (apiName) => !fieldsToRemove.includes(apiName)
    );
    setSelectedLeadFields(updatedFields);
    setSelectedSelected([]);
  };

  // Handler to open the add field dialog
  const handleOpenAddFieldDialog = () => {
    setNewFieldData({
      name: '',
      field_type: '',
      required: false,
    });
    setFieldErrors({});
    setOpenAddFieldDialog(true);
  };

  // Handler to close the add field dialog
  const handleCloseAddFieldDialog = () => {
    setOpenAddFieldDialog(false);
  };

  // Handler for new field form input change
  const handleNewFieldChange = (e) => {
    const { name, value } = e.target;
    setNewFieldData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handler to submit the new field
  const handleAddNewField = async () => {
    // Basic validation
    const errors = {};
    if (!newFieldData.name) errors.name = 'Field name is required';
    if (!newFieldData.field_type) errors.field_type = 'Field type is required';

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    try {
      // POST request to create the new lead field definition
      const payload = {
        ...newFieldData,
        campaign_model_id: campaignModelId,
        account_id: accountId,
      };
      await api.post('/crm/lead_field_definitions/', payload);
      showSnackbar('New field added successfully', 'success');
      setOpenAddFieldDialog(false);
      // Refresh the available lead fields
      fetchAvailableLeadFields();
    } catch (error) {
      console.error('Error adding new field:', error);
      showSnackbar('Failed to add new field', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDeleteDialog = () => {
    if (availableSelected.length === 0) {
      showSnackbar('No fields selected to delete.', 'info');
      return;
    }
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteFields = async () => {
    const fieldIdsToDelete = availableSelected
      .map((apiName) => {
        const field = availableLeadFields.find((f) => f.api_name === apiName);
        return field ? field.id : null;
      })
      .filter((id) => id !== null);

    try {
      // Make API calls to delete each selected field
      await Promise.all(
        fieldIdsToDelete.map((leadFieldDefinitionId) =>
          api.delete(`/crm/lead_field_definitions/${leadFieldDefinitionId}/`)
        )
      );
      showSnackbar('Selected fields deleted successfully.', 'success');
      fetchAvailableLeadFields();
      // Remove deleted fields from selectedLeadFields
      setSelectedLeadFields(
        selectedLeadFields.filter((apiName) => !availableSelected.includes(apiName))
      );
      setAvailableSelected([]);
    } catch (error) {
      console.error('Error deleting lead fields:', error);
      showSnackbar('Failed to delete selected fields', 'error');
    }
    handleCloseDeleteDialog();
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom color="textPrimary">
        Configure Lead Fields
      </Typography>
      {/* Add a button to open the Add Field Dialog */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', padding: 2 }}>
        <Button variant="contained" onClick={handleOpenAddFieldDialog}>
          Add New Field
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenDeleteDialog}
          sx={{ ml: 2 }}
          disabled={availableSelected.length === 0}
        >
          Delete Field
        </Button>
      </Box>
      <Grid container spacing={2}>
        {/* Available Lead Fields */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: 2, height: '400px', overflowY: 'auto' }}>
            <Typography variant="subtitle1">Available Lead Definitions</Typography>
            {availableLeadFields.length === 0 ? (
              <Typography variant="body2" sx={{ mt: 2 }}>
                No lead definitions in the system.
              </Typography>
            ) : (
              <List>
                {availableLeadFields
                  .filter((field) => !selectedLeadFields.includes(field.api_name))
                  .map((field) => {
                    const labelId = `available-list-label-${field.api_name}`;

                    return (
                      <ListItem
                        key={field.api_name}
                        button
                        onClick={handleToggleAvailable(field.api_name)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={availableSelected.includes(field.api_name)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={field.name} />
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </Paper>
        </Grid>

        {/* Action Buttons */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={handleAddFields}
            disabled={availableSelected.length === 0}
            sx={{ mb: 2 }}
          >
            Add &gt;&gt;
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRemoveFields}
            disabled={selectedSelected.length === 0}
          >
            &lt;&lt; Remove
          </Button>
        </Grid>

        {/* Selected Lead Fields */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: 2, height: '400px', overflowY: 'auto' }}>
            <Typography variant="subtitle1">Selected Lead Definitions</Typography>
            {selectedLeadFields.length === 0 ? (
              <Typography variant="body2" sx={{ mt: 2 }}>
                No lead definitions selected.
              </Typography>
            ) : (
              <List>
                {selectedLeadFields.map((apiName) => {
                  const field = availableLeadFields.find((f) => f.api_name === apiName);
                  const labelId = `selected-list-label-${apiName}`;

                  return (
                    <ListItem
                      key={apiName}
                      button
                      onClick={handleToggleSelected(apiName)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedSelected.includes(apiName)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={field ? field.name : apiName} />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Add Field Dialog */}
      <Dialog open={openAddFieldDialog} onClose={handleCloseAddFieldDialog}>
        <DialogTitle>Add New Lead Field</DialogTitle>
        <DialogContent>
          <TextField
            label="Field Name"
            name="name"
            value={newFieldData.name}
            onChange={handleNewFieldChange}
            fullWidth
            margin="normal"
            error={!!fieldErrors.name}
            helperText={fieldErrors.name}
          />
          <FormControl fullWidth margin="normal" error={!!fieldErrors.field_type}>
            <InputLabel>Field Type</InputLabel>
            <Select
              label="Field Type"
              name="field_type"
              value={newFieldData.field_type}
              onChange={handleNewFieldChange}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
              {/* Add other field types as needed */}
            </Select>
            {fieldErrors.field_type && (
              <FormHelperText>{fieldErrors.field_type}</FormHelperText>
            )}
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={newFieldData.required}
                onChange={(e) =>
                  setNewFieldData((prevData) => ({
                    ...prevData,
                    required: e.target.checked,
                  }))
                }
                name="required"
              />
            }
            label="Required"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddFieldDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleAddNewField} variant="contained">
            Add Field
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the selected fields? This action cannot be undone and
            will delete all associated data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDeleteFields} color="error" variant="contained">
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Save Configuration Button */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveLeadFieldConfig}
          disabled={false}
        >
          Save Configuration
        </Button>
      </Box>
    </Box>
  );
};

LeadFieldsConfiguration.propTypes = {
  campaignId: PropTypes.string.isRequired,
  campaignModelId: PropTypes.string.isRequired,
  selectedLeadFields: PropTypes.array.isRequired,
  setSelectedLeadFields: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  handleSaveLeadFieldConfig: PropTypes.func.isRequired,
};

export default LeadFieldsConfiguration;
