// src/components/NotificationIcon.js

import React from 'react';
import { IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import NotificationsPopover from './NotificationsPopover';
import theme from '../theme/theme';

const NotificationIcon = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notifications = useSelector((state) => state.notifications.items);
  const unreadCount = notifications.filter((n) => !n.is_read).length;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleClick}
        sx={{ color: theme.palette.text.primary }} // Ensure the icon color matches the theme
      >
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <NotificationsPopover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} />
    </>
  );
};

export default NotificationIcon;
