// src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Campaign as CampaignIcon,
  Message as MessageIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  FunnelChart,
  Funnel,
  LabelList,
} from 'recharts';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchDashboardData } from '../services/dashboardService';
import theme from '../theme/theme'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarStyles.css'; // Import after the default styles


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchDashboardDataRunner();
  }, [token, navigate]);

  const fetchDashboardDataRunner = async () => {
    try {
      const data = await fetchDashboardData();
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setDashboardData(null);
    } finally {
      setLoading(false);
    }
  };
  
  const localizer = momentLocalizer(moment);

  const calendarEvents =
    dashboardData?.upcoming_tasks?.map((task) => ({
      title: task.title,
      start: new Date(task.due_date),
      end: new Date(task.due_date),
    })) || [];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          mt: 10,
          background: `linear-gradient(to bottom, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
          minHeight: '100vh',
          padding: 2,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 700,
          }}
        >
          Welcome to your Novaura CRM!
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 500,
          }}
        >
          It looks like you haven’t set up any data yet. Let’s get started by configuring your account.
        </Typography>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                textTransform: 'none',
                padding: '12px 24px',
                borderRadius: '8px',
                fontSize: '16px',
                borderColor: theme.palette.secondary.main,
                color: theme.palette.text.primary,
              }}
              onClick={() => navigate('/crm/accounts')}
            >
              Configure your account!
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Destructure data with default values
  const {
    total_leads = 0,
    leads_converted = 0,
    active_campaigns = 0,
    interactions_today = 0,
    sentiment_score = 0,
    leads_funnel = [],
    sentiment_analysis = [],
    top_high_priority_leads = [],
    top_conversion_leads = [],
    pipeline_velocity = [],
    activity_feed = [],
    recent_interactions = [],
    top_performers = [],
    upcoming_tasks = [],
  } = dashboardData;

  // Sample data for charts if none exists
  const sampleFunnelData = [
    { stage: 'New Lead', count: 0 },
    { stage: 'Contacted', count: 0 },
    { stage: 'Qualified', count: 0 },
    { stage: 'Converted', count: 0 },
  ];

  const sampleSentimentData = [
    { name: 'Positive', value: 0 },
    { name: 'Neutral', value: 0 },
    { name: 'Negative', value: 0 },
  ];

  const COLORS = [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  const funnelData = leads_funnel.map((item) => ({
    name: item.stage,
    value: item.count,
  }));

  // Helper function for color coding
  const getPriorityColor = (score) => {
    let backgroundColor;

    if (score >= 80) {
      backgroundColor = theme.palette.error.light; // High priority - light red
    } else if (score >= 50) {
      backgroundColor = theme.palette.warning.light; // Medium priority - light orange
    } else {
      backgroundColor = theme.palette.success.light; // Low priority - light green
    }

    const textColor = theme.palette.getContrastText(backgroundColor);

    return { backgroundColor, textColor };
  };

  const handleFunnelClick = (data) => {
    const stage = data.payload.name;
    // Navigate to a page showing detailed leads in that stage
    navigate(`/crm/leads?stage=${encodeURIComponent(stage)}`);
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ color: theme.palette.text.primary }} gutterBottom>
        Dashboard
      </Typography>

      {/* Overview Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 1 }}>
                <GroupIcon />
              </Avatar>
              <Typography variant="h6">Total Leads</Typography>
              <Typography variant="h4">{total_leads}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Repeat similar blocks for other overview cards */}
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.success.main, mb: 1 }}>
                <TrendingUpIcon />
              </Avatar>
              <Typography variant="h6">Leads Converted</Typography>
              <Typography variant="h4">{leads_converted}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.info.main, mb: 1 }}>
                <CampaignIcon />
              </Avatar>
              <Typography variant="h6">Active Campaigns</Typography>
              <Typography variant="h4">{active_campaigns}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.warning.main, mb: 1 }}>
                <MessageIcon />
              </Avatar>
              <Typography variant="h6">Interactions Today</Typography>
              <Typography variant="h4">{interactions_today}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.secondary.main, mb: 1 }}>
                <SentimentSatisfiedIcon />
              </Avatar>
              <Typography variant="h6">Sentiment Score</Typography>
              <Typography variant="h4">{sentiment_score}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Tabs for Sections */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Dashboard Sections">
          <Tab label="Leads Management" />
          <Tab label="Interactions" />
          <Tab label="Campaigns" />
          <Tab label="Tasks & Calendar" />
          <Tab label="Top Performers" />
        </Tabs>
      </Box>

      {/* Tab Panels */}
      <TabPanel value={tabValue} index={0}>
        {/* Leads Management Components */}
        {/* High Priority Leads Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            High-Priority Leads
          </Typography>
          {top_high_priority_leads.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {top_high_priority_leads.map((lead) => {
                const { backgroundColor, textColor } = getPriorityColor(lead.score);
                return (
                  <TableRow
                    key={lead.id}
                    sx={{ backgroundColor }}
                  >
                    <TableCell sx={{ color: textColor }}>{lead.name}</TableCell>
                    <TableCell sx={{ color: textColor }}>{lead.score.toFixed(2)}</TableCell>
                  </TableRow>
                );
              })}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No high-priority leads available.</Typography>
          )}
        </Box>

        {/* Conversion Probability Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Leads with High Conversion Probability
          </Typography>
          {top_conversion_leads.length ? (
            top_conversion_leads.map((lead) => (
              <Card key={lead.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{lead.name}</Typography>
                  <Typography variant="body2">
                    Conversion Probability: {lead.conversion_probability.toFixed(2)}%
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No data available.</Typography>
          )}
        </Box>

        {/* Pipeline Velocity Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Pipeline Velocity Metrics
          </Typography>
          {pipeline_velocity.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Stage</TableCell>
                  <TableCell>Average Time (Hours)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pipeline_velocity.map((item) => (
                  <TableRow key={item.stage}>
                    <TableCell>{item.stage}</TableCell>
                    <TableCell>{item.average_time_hours.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No pipeline velocity data available.</Typography>
          )}
        </Box>

        {/* Leads Funnel Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Leads Funnel
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={leads_funnel.length ? leads_funnel : sampleFunnelData}
            >
              <XAxis dataKey="stage" tick={{ fill: theme.palette.text.primary }} />
              <YAxis tick={{ fill: theme.palette.text.primary }} />
              
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.divider,
                }}
                itemStyle={{ color: theme.palette.text.primary }}
                labelStyle={{ color: theme.palette.text.primary }}
              />
              <Line
                type="monotone"
                dataKey="count"
                stroke={theme.palette.primary.main}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        {/* Sales Pipeline */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Sales Pipeline
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <FunnelChart>
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.divider,
                }}
                itemStyle={{ color: theme.palette.text.primary }}
                labelStyle={{ color: theme.palette.text.primary }}
              />
              <Funnel
                dataKey="value"
                data={funnelData}
                isAnimationActive
                onClick={handleFunnelClick}
              >
                <LabelList position="right" fill={theme.palette.text.primary} stroke="none" dataKey="name" />
              </Funnel>
            </FunnelChart>
          </ResponsiveContainer>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {/* Interactions Components */}
        {/* Sentiment Analysis Overview */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Sentiment Analysis
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={
                  sentiment_analysis.length
                    ? sentiment_analysis
                    : sampleSentimentData
                }
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                labelLine={false}
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                labelStyle={{ fill: theme.palette.text.primary }}
              >
                {(sentiment_analysis.length
                  ? sentiment_analysis
                  : sampleSentimentData
                ).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.divider,
                }}
                itemStyle={{ color: theme.palette.text.primary }}
                labelStyle={{ color: theme.palette.text.primary }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Box>

        {/* Activity Feed */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Activity Feed
          </Typography>
          {activity_feed.length ? (
            activity_feed.map((activity, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="body2">
                    {activity.user} {activity.action} on{' '}
                    {new Date(activity.timestamp).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No recent activities.</Typography>
          )}
        </Box>
        {/* Recent Interactions */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Recent Interactions
          </Typography>
          {recent_interactions.length ? (
            recent_interactions.map((interaction) => (
              <Card key={interaction.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">
                    {interaction.interaction_type} with{' '}
                    {interaction.lead.first_name} {interaction.lead.last_name}
                  </Typography>
                  <Typography variant="body2">
                    {interaction.description}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {new Date(interaction.timestamp).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No recent interactions available.</Typography>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        {/* Campaign Performance Components */}
        {/* Active Campaigns (detailed view) */}
        {/* Campaign Performance Metrics */}
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        {/* Tasks & Calendar Components */}
        {/* Calendar Integration */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Calendar
          </Typography>
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
        </Box>
        {/* Upcoming Tasks/Reminders */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Upcoming Tasks
          </Typography>
          {upcoming_tasks.length ? (
            upcoming_tasks.map((task) => (
              <Card key={task.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{task.title}</Typography>
                  <Typography variant="body2">
                    Due: {new Date(task.due_date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">Priority: {task.priority}</Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No upcoming tasks.</Typography>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={4}>
        {/* Top Performers Components */}
        {/* Top Performing Leads/Campaigns */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Top Performers
          </Typography>
          {top_performers.length ? (
            top_performers.map((item) => (
              <Card key={item.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{item.name}</Typography>
                  <Typography variant="body2">
                    Engagement Score: {item.engagement_score}
                  </Typography>
                  <Typography variant="body2">
                    Conversion Rate: {item.conversion_rate}%
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Last Interaction:{' '}
                    {new Date(item.last_interaction_date).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No top performers available.</Typography>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default Dashboard;
