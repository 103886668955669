// src/redux/scheduledReachOutsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../services/authService';

// Create a scheduled reach-out
export const createScheduledReachOutThunk = createAsyncThunk(
  'scheduledReachOuts/createScheduledReachOut',
  async (scheduledReachOutData, { rejectWithValue }) => {
    try {
      const response = await api.post('/crm/scheduled-reachouts/', scheduledReachOutData);
      return response.data;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || 'Error creating scheduled reach-out'
      );
    }
  }
);

// Fetch scheduled reach outs for a specific campaign
export const getScheduledReachOuts = createAsyncThunk(
  'scheduledReachOuts/getScheduledReachOuts',
  async (campaignId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/crm/scheduled-reachouts/?campaign=${campaignId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || 'Error fetching scheduled reach outs'
      );
    }
  }
);

// Delete a scheduled reach out
export const deleteScheduledReachOutThunk = createAsyncThunk(
  'scheduledReachOuts/deleteScheduledReachOut',
  async (scheduledReachOutId, { rejectWithValue }) => {
    try {
      await api.delete(`/crm/scheduled-reachouts/${scheduledReachOutId}/`);
      return scheduledReachOutId;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || 'Error deleting scheduled reach out'
      );
    }
  }
);

// Update a scheduled reach out
export const updateScheduledReachOutThunk = createAsyncThunk(
  'scheduledReachOuts/updateScheduledReachOut',
  async ({ scheduledReachOutId, scheduledReachOutData }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/crm/scheduled-reachouts/${scheduledReachOutId}/`,
        scheduledReachOutData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || 'Error updating scheduled reach out'
      );
    }
  }
);

const scheduledReachOutsSlice = createSlice({
  name: 'scheduledReachOuts',
  initialState: {
    scheduledReachOuts: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch scheduled reach outs
      .addCase(getScheduledReachOuts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getScheduledReachOuts.fulfilled, (state, action) => {
        state.scheduledReachOuts = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getScheduledReachOuts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Delete scheduled reach out
      .addCase(deleteScheduledReachOutThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteScheduledReachOutThunk.fulfilled, (state, action) => {
        const id = action.payload;
        state.scheduledReachOuts = state.scheduledReachOuts.filter(
          (item) => item.id !== id
        );
        state.status = 'succeeded';
      })
      .addCase(deleteScheduledReachOutThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Update scheduled reach out
      .addCase(updateScheduledReachOutThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateScheduledReachOutThunk.fulfilled, (state, action) => {
        const updatedItem = action.payload;
        const index = state.scheduledReachOuts.findIndex(
          (item) => item.id === updatedItem.id
        );
        if (index !== -1) {
          state.scheduledReachOuts[index] = updatedItem;
        }
        state.status = 'succeeded';
      })
      .addCase(updateScheduledReachOutThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createScheduledReachOutThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createScheduledReachOutThunk.fulfilled, (state, action) => {
        state.scheduledReachOuts.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createScheduledReachOutThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default scheduledReachOutsSlice.reducer;
